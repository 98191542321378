// @flow
import * as React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import _, { isEmpty } from "lodash";
import Slider from "react-slick";

import * as actions from "../modules/actions";
import * as selectors from "../modules/selectors";
import type { TUiGameSelectionFavourite } from "../modules/types/store/uiGameSelectionReducer";

import GameSelectionBgImg from "../assets/images/game-selection-bg.webp";
import LogoAFLClassicImg from "../assets/images/classic-logo-toyota.png";
import LogoAFLDraftImg from "../assets/images/draft-logo-toyota.png";
// import UFLogo from "../assets/images/uf-logo.svg";
import colors from "../assets/css/colors";
import { above, below } from "../assets/css/media";

import {
	Ad,
	AdsContainer,
	ButtonPrimaryLink,
	// Exist,
	Footer,
	PageContentWrapper,
	Preloader,
	SearchField,
	SearchInputWrapper,
	SearchSubmitButton,
	TeamUserAvatar,
} from "../components";
import JsonFetcher from "../components/utils/JsonFetcher";

import CautionIcon from "../components/Icons/Caution";
import OverviewIcon from "../components/Icons/Overview";
import SearchIcon from "../components/Icons/Search";
import { IS_DISABLE_LIVE_DRAFT, SEASON_YEAR } from "../modules/constants";
import type { TPLeague, TRound, TClassicTeam, TUser } from "../modules/types";
// import { isAllTrue } from "../helpers";
import BoxDottedBorder from "./gameSelection/boxDottedBorder";
import BoxLeague, {
	BoxLeagueNameText,
	BoxLeagueNameSubText,
	BoxInner
} from "./gameSelection/boxLeague";
import { FavouriteStarIcon, FavouriteButton } from "./gameSelection/favouriteButton";
import { SelectLeague } from "./gameSelection/league";



// const UltimateFootyWrapper = styled.div`
// 	box-sizing: border-box;
// 	width: 100%;
// 	background: #001F4A;
// 	border-radius: 5px;
// 	padding: 20px;
// 	margin: 40px 0 10px 0;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: center;
// 	position: relative;
// 	padding-top: 50px;
// 	.uf-logo{
// 		height: 64px;
// 		width: auto;
// 		position: absolute;
// 		top: -30px;
// 	}
// `;

// const UFHeading = styled.h1`
// 	font-family: SourceSansPro;
// 	font-size: 20px;
// 	line-height: 24px;
// 	color: #FFFFFF;
// 	font-weight: 600;
// 	text-align: center;
// 	margin-bottom: 10px;

// `;

// const UFParagraph = styled.p`
// 	font-family: SourceSansPro;
// 	font-size: 16px;
// 	line-height: 20px;
// 	color: #FFFFFF;
// 	font-weight: 400;
// 	text-align: center;
// `;

// const UFLinkButton = styled(ButtonPrimaryLink)`
// 	background: linear-gradient(90deg, #C8951A 0%, #F6E7A6 48.96%, #C8951A 100%);
// 	color: #181817;
// 	width: 340px;
// 	margin-top: 20px;
// 	font-size: 16px;
// 	margin-bottom: 10px;
// `;
const PageOutterWrapper = styled.div`
	position: relative;
	overflow: hidden;
	min-height: 620px;
`;

const BannerWrapper = styled.div`
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	background-image: url(${GameSelectionBgImg});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 10% top;
	min-height: 620px;
`;

const AboveTablet = styled.div`
	${below.tablet`
		display: none;
	`};
`;

const BelowTablet = styled.div`
	${above.tablet`
		display: none;
	`};
`;

const TwoColumnEqualLayout = styled.div`
	display: flex;
	justify-content: space-between;
`;

const Column = styled.div`
	flex: 0 0 46%;
	display: flex;
	flex-direction: column;
    align-items: center;
`;

const GameLogoWrap = styled.div`
	width: 200px;
	${below.tablet`
		margin: 0 auto;
	`};
`;

const GameLogo = styled.img`
	position: relative;
	display: block;
	max-width: 100%;
	margin: 0 auto 20px;
	transition: transform 0.5s, opacity 0.5s, margin 0.5s;
	z-index: 1;

	.slick-slide:not(.slick-active) & {
		margin: 0 0 20px ${({ first }) => first ? "auto" : "0px"};
		transform: translateX(${({ first }) => first ? "42%" : "-42%"}) scale(0.8);
		opacity: 0.3;
		z-index: 0;
	}
`;

const GameInfoHeaderText = styled.h3`
	font-size: 32px;
	font-weight: 700;
	line-height: 1.1;
	text-align: center;
	color: ${colors.primary.primary};
	margin: 10px 0;
`;

const GameInfoText = styled.p`
	max-width: 450px;
	font-size: 16px;
	line-height: 1.25;
	color: ${colors.primary.primary};
	text-align: center;
	margin: 10px auto;
	font-family: SourceSansPro;
	min-height: 140px;
	${below.tablet`
		min-height: inherit;
	`};
`;

const GameInfo = styled.div`
	margin: 10px 0 20px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    width: 100%;
	padding: 0 20px;
	${below.tablet`
		padding: 20px;
	`};
`;

const BoxIconWrapper = styled.div`
	display: flex;
	justify-content: center;
	font-size: 40px;
	color: ${colors.primary.primary};
	margin-bottom: 10px;
`;

const BoxActionText = styled.div`
	font-size: 18px;
	font-weight: 500;
	text-align: center;
	line-height: 1.2;
	color: ${colors.primary.primary};
	margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
	display: flex;

	> * {
		flex: 0 1 50%;
		margin-right: 10px;
	}
	> *:last-child {
		margin-right: 0px;
	}
`;

const TeamUserAvatarWrapper = styled.div`
	width: 60px;
	height: 60px;
	margin: -50px auto 10px;
`;

const SliderWrapper = styled.div`
	.slick-track {
		display: flex;
	}

	.slick-slide:first-child {
		margin-right: 5px;
	}

	.slick-slide:last-child {
		margin-left: 5px;
	}
`;
type Props = {
	setGameSelectionFavourite: typeof actions.uiGameSelection.setFavourite,
	clearGameSelectionFavourite: typeof actions.uiGameSelection.clearFavourite,
	fetchMyClassicLeagues: typeof actions.showMyClassicLeagues,
	fetchMyClassicTeam: typeof actions.fetchMyClassicTeam,
	fetchMyDraftLeagues: typeof actions.leagueDraft.showMyDraftLeagues,
	my_draft_leagues: Array<Object>,
	has_draft_leagues: boolean,
	my_classic_leagues: Array<Object>,
	fetchUser: typeof actions.fetchUser,
	has_classic_leagues: boolean,
	my_classic_team: TClassicTeam,
	is_classic_team_complete: boolean,
	fetchClassicRegenerateList: typeof actions.regenerateListClassicLeagues,
	fetchRegenerateClassicLeague: typeof actions.regenerateShowClassicLeague,
	fetchDraftRegenerateList: typeof actions.leagueDraft.regenerateListDraftLeagues,
	fetchRegenerateDraftLeague: typeof actions.leagueDraft.regenerateShowDraftLeague,
	regenerateShowDraftLeagueUF: typeof actions.leagueDraft.regenerateShowDraftLeagueUF,
	clearLeagueCreateId: typeof actions.leagueDraft.clearUFDraftLeagueCreate,
	user: TUser,
	classic_regenerate_list: Object,
	draft_regenerate_list: Object,
	classic_regen_leagues: TPLeague[],
	draft_regen_leagues: TPLeague[],
	draft_regen_leagues_uf: TPLeague[],
	uf_data: Object,
	userIsPending: boolean,
	history: {
		push: Function
	},
	game_selection_favourite: TUiGameSelectionFavourite,
	location: Object,
	leaveClassicLeague: Function,
	leaveDraftLeague: Function,
	actual_round: TRound,
}

type State = {
	search_classic_join: string,
	search_draft_join: string,
	selected_regenerated_league_id: number
}

class GameSelection extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, [
			"favourite",
			"clearFavourite",
			"handleChange",
			"submitClassicJoin",
			"submitDraftJoin",
			"renderDraft",
			"renderClassic",
		]);
	}

	state = {
		search_classic_join: "",
		search_draft_join: "",
		selected_regenerated_league_id: 0,
	};

	componentDidMount() {
		const {
			fetchMyClassicTeam,
			fetchUser,
			fetchClassicRegenerateList,
			fetchDraftRegenerateList,
			clearLeagueCreateId
		} = this.props;
		clearLeagueCreateId();
		fetchUser();
		
		fetchMyClassicTeam();
		
		this.fetchLeagues();
		fetchClassicRegenerateList();
		fetchDraftRegenerateList();
	}

	componentDidUpdate(prev_props: Props) {

		if(!isEmpty(this.props.classic_regenerate_list) 
			&& prev_props.classic_regenerate_list.length === 0 
			&& this.props.classic_regenerate_list.length > 0) {
			this.props.classic_regenerate_list.forEach(v => {
				this.props.fetchRegenerateClassicLeague({ id: v.id });
			});
		}
		if(	!isEmpty(this.props.draft_regenerate_list) &&
			prev_props.draft_regenerate_list.length === 0 
			&& this.props.draft_regenerate_list.length > 0) {
			this.props.draft_regenerate_list.forEach(v => v.is_uf ? 
				this.props.regenerateShowDraftLeagueUF({ 
					id: v.id, 
					game:"uf"
				}):this.props.fetchRegenerateDraftLeague({ 
					id: v.id, 
					game: "fantasy"
				})
			);
		}
	}

	slider = React.createRef();

	

	favourite({ game, type, id }) {
		const { setGameSelectionFavourite, user } = this.props;
		setGameSelectionFavourite({ user_id: user.id, game, type, id });
	}

	clearFavourite() {
		const { clearGameSelectionFavourite, user } = this.props;
		clearGameSelectionFavourite({ user_id: user.id });
	}

	handleChange(event) {
		const target = event.target;
		const value = target.type === "checkbox" ? target.checked : target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	}

	submitClassicJoin(e) {
		e.preventDefault();
		this.props.history.push(`/classic/leagues/join/${this.state.search_classic_join}`);
	}

	submitDraftJoin(e) {
		e.preventDefault();
		this.props.history.push(`/draft/leagues/join/${this.state.search_draft_join}`);
	}

	get isDraft() {
		return window.location.pathname.substr(0, 6) === "/draft";
	}


	get isClassic() {
		return window.location.pathname.substr(0, 8) === "/classic";
	}

	fetchLeagues(){
		const {
			fetchMyClassicLeagues,
			fetchMyDraftLeagues
		} = this.props;
		fetchMyDraftLeagues();
		fetchMyClassicLeagues();
		
	}
	renderClassicTeam() {
		const {
			my_classic_team,
			is_classic_team_complete,
			game_selection_favourite,
			user
		} = this.props;

		if (my_classic_team && my_classic_team.value === 0) {
			return (
				<BoxDottedBorder>
					<BoxInner>
						<BoxIconWrapper>
							<CautionIcon />
						</BoxIconWrapper>
						<BoxActionText>
							You are yet to select your team
						</BoxActionText>
						<ButtonPrimaryLink to={"/classic/team"}>
							Select your team
						</ButtonPrimaryLink>
					</BoxInner>
				</BoxDottedBorder>
			);
		}

		if (!is_classic_team_complete) {
			return (
				<BoxDottedBorder>
					<BoxInner>
						<BoxIconWrapper>
							<CautionIcon />
						</BoxIconWrapper>
						<BoxActionText>
							Your team is currently incomplete
						</BoxActionText>
						<ButtonPrimaryLink to={"/classic/team"}>
							Complete your team
						</ButtonPrimaryLink>
					</BoxInner>
				</BoxDottedBorder>
			);
		}

		const favourite_object = {
			game: "classic", type: "team", id: my_classic_team.id
		};
		const is_favourite = _.isEqual(favourite_object, game_selection_favourite);
		const favouriteFunction = is_favourite ?
			this.clearFavourite : () => this.favourite(favourite_object);

		return (
			<BoxLeague>
				<FavouriteButton
					onClick={favouriteFunction}
					active={is_favourite}
				>
					<FavouriteStarIcon />
				</FavouriteButton>
				<BoxInner>
					<TeamUserAvatarWrapper>
						<TeamUserAvatar
							{...my_classic_team}
							avatar_version={user.avatar_version}
							firstname={user.firstname}
							lastname={user.lastname}
							is_classic
						/>
					</TeamUserAvatarWrapper>
					<BoxLeagueNameText>{my_classic_team.name}</BoxLeagueNameText>
					<BoxLeagueNameSubText>
						{`${user.firstname} ${user.lastname}`}
					</BoxLeagueNameSubText>
					<ButtonPrimaryLink to={"/classic/team"}>
						View your team
					</ButtonPrimaryLink>
				</BoxInner>
			</BoxLeague>
		);
	}

	renderClassicLeagues() {
		const { my_classic_leagues } = this.props;
		
		return my_classic_leagues.map(league => league && this.renderLeagueContent(league, false));
	}

	renderClassicJoinCreate() {
		const { has_classic_leagues } = this.props;

		return (
			<BoxDottedBorder>
				<BoxInner>
					<BoxIconWrapper>
						{has_classic_leagues ? <OverviewIcon /> : <CautionIcon />}
					</BoxIconWrapper>
					<BoxActionText>
						{has_classic_leagues ?
							"Join or create another classic league" :
							"You are not in any league"
						}
					</BoxActionText>
					<ButtonGroup>
						<ButtonPrimaryLink to={"/classic/leagues/join"}>
							Join a classic league
						</ButtonPrimaryLink>
						<ButtonPrimaryLink to={"/classic/leagues/create"}>
							Create a classic league
						</ButtonPrimaryLink>
					</ButtonGroup>
				</BoxInner>
			</BoxDottedBorder>
		);
	}

	renderClassicJoinWithPin() {
		return (
			<BoxDottedBorder>
				<BoxInner>
					<BoxActionText>
						Already have a classic league PIN?
					</BoxActionText>
					<form onSubmit={this.submitClassicJoin}>
						<SearchInputWrapper>
							<SearchField
								id="search_classic_join"
								name="search_classic_join"
								onChange={this.handleChange}
								placeholder="Enter a private PIN"
							/>
							<SearchSubmitButton type="submit">
								<SearchIcon />
							</SearchSubmitButton>
						</SearchInputWrapper>
					</form>
				</BoxInner>
			</BoxDottedBorder>
		);
	}

	renderClassicRegenLeagues() {
		const { classic_regen_leagues } = this.props;

		return classic_regen_leagues.map(
			league => 
				this.renderRegenerateLeague(
					league,
					true,
					false
				));
	}

	renderDraftRegenLeagues() {
		const {draft_regen_leagues} = this.props;
		return draft_regen_leagues.map(league => this.renderRegenerateLeague(league, false, false));
	}

	renderDraftRegenLeaguesUF() {
		const { draft_regen_leagues_uf} = this.props;
		return 	draft_regen_leagues_uf
			.filter(item => !item.regenerated)
			.map(league => 
				this.renderRegenerateLeague(
					league,
					false,
					false
				)
			);
	}

	// renderDraftRegenLeaguesUFNonCommish() {
	// 	const {
	// 		draft_regenerate_list
	// 	} = this.props;
	// 	return draft_regenerate_list
	// 		.filter(item => isAllTrue([
	// 			Boolean(item.is_uf),
	// 			!Boolean(item.regenerated),
	// 			!Boolean(item.is_commissioner),
	// 		]))
	// 		.map(league => this.renderRegenerateLeague(
	// 			league,
	// 			false,
	// 			true
	// 		));
	// }

	renderLeagueContent(league: TPLeague, is_draft: boolean) {
		const {
			game_selection_favourite,
			user,
			actual_round,
		} = this.props;

		return <SelectLeague
			key={league.id}
			league={league}
			is_draft={is_draft}
			is_regenerate={false}
			game_selection_favourite={game_selection_favourite}
			clearFavourite={this.clearFavourite}
			setFavourite={this.favourite}
			user={user}
			actual_round={actual_round}
			hide_regenerate_button={false}
		/>;
	}

	renderRegenerateLeague(
		league: TPLeague,
		is_classic: boolean,
		hide_regenerate_button: boolean
	) {
		const {
			game_selection_favourite,
			user,
			actual_round,
		} = this.props;
		return <SelectLeague
			key={league.id}
			league={league}
			is_draft={!is_classic}
			is_regenerate={true}
			game_selection_favourite={game_selection_favourite}
			clearFavourite={this.clearFavourite}
			setFavourite={this.favourite}
			user={user}
			actual_round={actual_round}
			hide_regenerate_button={hide_regenerate_button}
		/>;
	}

	renderDraftLeagues() {
		const { my_draft_leagues } = this.props;

		return my_draft_leagues.map(league => league && this.renderLeagueContent(league, true));
	}

	renderDraftJoinCreate() {
		const { has_draft_leagues } = this.props;

		return (
			<BoxDottedBorder>
				<BoxInner>
					<BoxIconWrapper>
						{has_draft_leagues ? <OverviewIcon /> : <CautionIcon />}
					</BoxIconWrapper>
					<BoxActionText>
						Join {!IS_DISABLE_LIVE_DRAFT && "or create"} another draft league
					</BoxActionText>
					<ButtonGroup>
						<ButtonPrimaryLink to={"/draft/leagues/join"}>
							Join a draft league
						</ButtonPrimaryLink>
						<ButtonPrimaryLink to={"/draft/leagues/create"}>
							Create a draft league
						</ButtonPrimaryLink>
					</ButtonGroup>
				</BoxInner>
			</BoxDottedBorder>
		);
	}

	renderUltimateFootyNotice() {
		// const {user, uf_data} = this.props;
		// const ufUser_id = get(user,"uf_mapped_user_id",0);
		// const ufDataIsLinked = uf_data.is_linked;
		// const hideLinkUF = Boolean(ufUser_id) || ufDataIsLinked;
		// const showUFLink = true;
		// REMOVED IN https://geniussports.atlassian.net/browse/F2P1-38900
		return null;
		// return (
		// 	<UltimateFootyWrapper>
		// 		<img src={UFLogo} className="uf-logo" alt="Ultimate Footy Logo"/>
		// 		<UFHeading>
		// 			Did you play Ultimate Footy last year?
		// 		</UFHeading>
		// 		<Exist when={showUFLink}>
		// 			<UFParagraph>
		// 				Ultimate Footy has merged into AFL Fantasy this year. 
		// 				Bring your leagues over by linking your account.
		// 			</UFParagraph>
		// 			<UFLinkButton to={'/link-ultimate-footy'}>
		// 				Link Ultimate Footy Account
		// 			</UFLinkButton>
		// 		</Exist>
		// 		<Exist when={!showUFLink}>
		// 			<UFParagraph>
		// 				Ultimate Footy will be merging into AFL Fantasy Draft in the coming weeks.
		// 				Once complete, you will be able to continue your Ultimate Footy leagues by 
		// 				linking your account.
		// 			</UFParagraph>
		// 		</Exist>
		// 	</UltimateFootyWrapper>
		// );
	}
	// REMOVED IN https://geniussports.atlassian.net/browse/F2P1-38900
	// renderUFLinked() {
	// 	const {user, uf_data} = this.props;
	// 	const ufUser_id = get(user,"uf_mapped_user_id",0);
	// 	const ufDataIsLinked = uf_data.is_linked;
	// 	const hasLink = ufUser_id || ufDataIsLinked;
	// 	if(!hasLink){
	// 		return null;
	// 	}
	// 	return <UltimateFootyWrapper>
	// 		<img src={UFLogo} className="uf-logo" alt="Ultimate Footy Logo"/>
	// 		<UFHeading>
	// 			Successfully linked account
	// 		</UFHeading>
	// 		<UFParagraph>
	// 			Your Ultimate Footy account has been linked and you can now access
	// 			any leagues and teams that have been restored by your league commissioner.
	// 		</UFParagraph>
		
	// 	</UltimateFootyWrapper>;
		
	// }

	renderDraftJoinWithPin() {
		return (
			<BoxDottedBorder>
				<BoxInner>
					<BoxActionText>
						Already have a draft league PIN?
					</BoxActionText>
					<form onSubmit={this.submitDraftJoin}>
						<SearchInputWrapper>
							<SearchField
								id="search_draft_join"
								name="search_draft_join"
								onChange={this.handleChange}
								placeholder="Enter a private PIN"
							/>
							<SearchSubmitButton type="submit">
								<SearchIcon />
							</SearchSubmitButton>
						</SearchInputWrapper>
					</form>
				</BoxInner>
			</BoxDottedBorder>
		);
	}

	renderClassic() {
		return (
			<React.Fragment>
				<GameLogoWrap>
					<GameLogo
						alt="Fantasy Classic"
						first
						src={LogoAFLClassicImg}
						onClick={e => {
							e.preventDefault();
							if (
								this.slider &&
								this.slider.current &&
								typeof this.slider.current.slickPrev === "function"
							) {
								this.slider.current.slickPrev();
							}
						}}
					/>
				</GameLogoWrap>

				<GameInfo>
					<GameInfoHeaderText>
						AFL Fantasy Classic {SEASON_YEAR}
					</GameInfoHeaderText>
					<GameInfoText>Play our famous salary cap game
					to win amazing prizes!<br />
					You must select 30 players that fit within the budget
					provided to build your squad.<br />
					You have the opportunity to make changes to your team
					each week and can join leagues to play against your mates or other AFL
					Fantasy enthusiasts.
					</GameInfoText>
				</GameInfo>

				{this.renderClassicTeam()}
				{this.renderClassicLeagues()}
				{this.renderClassicRegenLeagues()}
				{this.renderClassicJoinCreate()}
				{this.renderClassicJoinWithPin()}
			</React.Fragment>
		);
	}

	renderDraft() {
		return (
			<React.Fragment>
				<GameLogoWrap>
					<GameLogo
						alt="Fantasy Draft"
						src={LogoAFLDraftImg}
						onClick={e => {
							e.preventDefault();
							if (
								this.slider &&
								this.slider.current  &&
								typeof this.slider.current.slickNext === "function"
							) {
								this.slider.current.slickNext();
							}

						}}
					/>
				</GameLogoWrap>
				<GameInfo>
					<GameInfoHeaderText>AFL Fantasy Draft {SEASON_YEAR}</GameInfoHeaderText>
					<GameInfoText>
						{`Enter your own Draft to build your team within your league.
						Each AFL player can only be on one team within your league
						and once they’re yours, they’re yours! Throughout the season
						you can trade with other teams or go back to the player pool
						to improve your squad. A great option for those who are keen
						to take their Fantasy experience to the next level.`}
					</GameInfoText>
				</GameInfo>

				{this.renderDraftLeagues()}
				{this.renderDraftRegenLeagues()}
				{/* {this.renderDraftRegenLeaguesUF()} */}
				{/* {this.renderDraftRegenLeaguesUFNonCommish()} */}
				{this.renderDraftJoinCreate()}
				{/* {this.renderUltimateFootyNotice()}
				{this.renderUFLinked()} */}
				{this.renderDraftJoinWithPin()}
			</React.Fragment>
		);
	}

	render() {
		const {user, userIsPending} = this.props;
		if(!user || userIsPending){
			return <Preloader />;
		}
		return (
			<React.Fragment>
				<JsonFetcher fetch={["players", "rounds", "squads", "venues"]} />
				<AdsContainer>
					<Ad id="gameselection" />
				</AdsContainer>

				<PageOutterWrapper>
					<BannerWrapper />
					<PageContentWrapper>
						<AboveTablet>
							<TwoColumnEqualLayout>
								<Column>{this.renderClassic()}</Column>
								<Column>{this.renderDraft()}</Column>
							</TwoColumnEqualLayout>
						</AboveTablet>

						<BelowTablet>
							<SliderWrapper>
								<Slider ref={this.slider} {...{
									speed: 500,
									infinite: false,
									slidesToShow: 1,
									slidesToScroll: 1,
									arrows: false
								}}>
									{this.renderClassic()}
									{this.renderDraft()}
								</Slider>
							</SliderWrapper>
						</BelowTablet>
					</PageContentWrapper>
					<Footer />
				</PageOutterWrapper>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const my_classic_leagues = selectors.leaguesClassic.show_my.getLeagues(state, props);
	const my_draft_leagues = selectors.leagues.show_my.getLeagues(state);
	return {
		user: selectors.getUser(state),
		// Classic
		my_classic_leagues,
		has_classic_leagues: !_.isEmpty(my_classic_leagues),
		my_classic_team: selectors.getMyClassicTeam(state),
		is_classic_team_complete: selectors.isMyClassicTeamComplete(state, props),
		classic_regenerate_list: state.leaguesClassic.regenerate_list.result || [],
		classic_regen_leagues: 
			state.leaguesClassic.regenerate_list.result
				.filter(l => l.id in state.leaguesClassic.regenerate_show.by_id)
				.map(l => state.leaguesClassic.regenerate_show.by_id[l.id]),
		// Draft
		my_draft_leagues,
		has_draft_leagues: !_.isEmpty(my_draft_leagues),
		game_selection_favourite: selectors.uiGameSelection.getFavourite(state),
		actual_round: selectors.rounds.getActualRound(state),
		draft_regenerate_list: state.leagues.regenerate_list.result || [],
		draft_regen_leagues: state.leagues.regenerate_list.result
			.filter(l => l.id in state.leagues.regenerate_show.by_id)
			.map(l => state.leagues.regenerate_show.by_id[l.id]) || [],
		draft_regen_leagues_uf: state.leagues.regenerate_list.result
			.filter(l => l.id in state.leagues.regenerate_show_uf.by_id)
			.map(l => state.leagues.regenerate_show_uf.by_id[l.id]) || [],
		uf_data: selectors.getUltimateFootyData(state),
		userIsPending: state.user.user.is_pending,
	};
};

const mapDispatchToProps = {
	setGameSelectionFavourite: actions.uiGameSelection.setFavourite,
	clearGameSelectionFavourite: actions.uiGameSelection.clearFavourite,
	fetchUser: actions.fetchUser,
	// Classic
	fetchMyClassicLeagues: actions.showMyClassicLeagues,
	fetchMyClassicTeam: actions.fetchMyClassicTeam,
	fetchClassicRegenerateList: actions.regenerateListClassicLeagues,
	fetchRegenerateClassicLeague: actions.regenerateShowClassicLeague,
	// Draft
	fetchMyDraftLeagues: actions.leagueDraft.showMyDraftLeagues,
	fetchDraftRegenerateList: actions.leagueDraft.regenerateListDraftLeagues,
	fetchRegenerateDraftLeague: actions.leagueDraft.regenerateShowDraftLeague,
	regenerateShowDraftLeagueUF: actions.leagueDraft.regenerateShowDraftLeagueUF,
	clearLeagueCreateId: actions.leagueDraft.clearUFDraftLeagueCreate
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GameSelection);