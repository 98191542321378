// @flow
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { compose } from "redux";

import { below, above } from "../../../assets/css/media";
import * as selectors from "../../../modules/selectors";
import * as actions from "../../../modules/actions";

import type { 
	TRound, 
	TPLeague, 
	TRoundsById, 
	TPlayersById,
	TCoachPlayerStatsById,
	TClassicTeamsByID,
	TUser
} from "../../../modules/types";

import {
	TwoColumnLayout,
	RoundSelector,
	PageContentWrapper,
	PageTitle,
	Footer,
	AdsContainer,
	Ad,
	PageSubTitle,
	ButtonPrimaryLink,
	MatchCentreBar,
	ClassicLadderOpen,
	StandardSidebar,
	AdFantasyCoach,
	H2HLadder,
	LeagueGameBar,
	Fixtures,
} from "../../../components";
import JsonFetcher from "../../../components/utils/JsonFetcher";
import withRoundsSelector from "../../../components/utils/withRoundsSelector";

import {
	getLeagueRounds,
	getLeagueRoundAliases,
	getLeagueLadderRound,
	getLeagueFinalFullNames,
	hasLeagueFinished,
	getWinners
} from "../../../helpers/league";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import Premiers from "../../../components/Premiers";
import FinalsModal from "../../../components/Fixtures/FinalsModal";

const PageSubTitleStyled = styled(PageSubTitle)`
	display: flex;
    justify-content: space-between;
    align-items: center;
`;
const FavouriteButton = styled.button`
	padding: 1px 10px;
	width: fit-content;
	height: 20px;
	border-radius: 2px;
	background-color: ${({ is_favourite }) => is_favourite ? "#5410A2" : "#fff"};
	color: ${({ is_favourite }) => is_favourite ?  "#fff" : "#5410A2"};
	border: ${({ is_favourite }) => is_favourite ?  "none" : "1px solid #5410A2"};
	font-family: SourceSansPro;
	font-weight: 400;
	text-align: center;
	margin-top: 3px;
	margin-left: 10px;
	cursor: pointer;
	outline: none;
	${below.tablet`
		display: none;
	`}
`;
const TitleFavouriteWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
`;

const BelowTabletWrapper = styled.div`
	${above.tablet`
		display: none;
	`}
`;
const AboveTabletWrapper = styled.div`
	${below.tablet`
		display: none;
	`}
`;
const PageHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-items: center;
	justify-content: space-between;

`;
const StyledButtonPrimaryLink = styled(ButtonPrimaryLink)`
	border: none;
	background-color: transparent;
	color: #1D4073;
	font-weight: 400;
	text-decoration: underline;
	:hover{
		background-color: transparent;

	}
	${below.tablet`
			display: none;
	`}
`;

const FinalsInfoButton = styled.button`
	outline: none;
	color: #1D4073;
	font-family: SourceSansPro;
	font-size: 12px;
	font-weight: 400;
	line-height: 1;
	cursor: pointer;
	width: auto;
	text-align: left;
	text-decoration: underline;
	border: none;
	padding: 0;
	background-color: transparent;
	margin: 5px 0;
`;

type Props = {
	selected_league_id: number,
	ladder_teams_by_id: TClassicTeamsByID,
	ladder_teams_ordered: Array<Object>,
	fetchLeague: typeof actions.showClassicLeague,
	fetchLadder: typeof actions.ladderClassicLeague,
	is_pending_show: boolean,
	is_pending_ladder: boolean,
	user: TUser,
	rounds_ordered: Array<Object>,
	rounds_by_id: TRoundsById,
	actual_round: TRound,
	selected_round_id: number,
	setSelectedRoundId: Function,
	league: TPLeague,
	has_assistant_coach: boolean,
	coach_players_by_id: TCoachPlayerStatsById,
	show_league_success: boolean,
	match: {
		params: {league_id: string}
	},
	location: {
		pathname: string
	},
	last_complete_round: TRound,
	players_by_id: TPlayersById,
	rounds_with_matches: Array<Object>,
	fetchLeagueRosters: typeof actions.getClassicLeagueRosters,
	setFavourite: Function,
	clearFavourite: Function,
	favourite: Object,
  user_id: number,
}

type State = {
	is_finals_modal_open: boolean,
}

class LeagueOverviewPage extends React.Component<Props, State> {
	constructor(props) {
		super(props);

		_.bindAll(this, [
			"setSelectedRoundIdIfOutsideLeagueRounds",
			"handleFinalsModal"
		]);
	}
	state = {
		is_finals_modal_open: false,
	};
	componentDidMount() {
		const { selected_round_id } = this.props;

		this.fetchLeague();

		if (selected_round_id) {
			this.fetchLeagueRosters(selected_round_id);
			this.fetchLadder();
		}
	}

	componentDidUpdate(prev_props) {
		const { selected_league_id, selected_round_id, league } = this.props;
		const league_type = _.get(league, "type");
		const isLeagueIdReady = selected_league_id && selected_league_id !== prev_props.selected_league_id;
		const isLeagueReadyAndH2H = isLeagueIdReady &&  league_type !== "open";
		if (
			isLeagueIdReady
		) {
			this.fetchLeague();
			this.fetchLadder();
		}
		else if (
			selected_round_id !== prev_props.selected_round_id
		) {
			this.fetchLadder();
		}

		// Fetching ladder overwrites the teams' rosters, so refetch them
		if (isLeagueReadyAndH2H) {
			this.fetchLeagueRosters(selected_round_id);
			this.fetchLadder();
		}

		this.setSelectedRoundIdIfOutsideLeagueRounds(prev_props);
	}

	fetchLeagueRosters(round_id) {
		const { fetchLeagueRosters, selected_league_id, league } = this.props;
		const league_type = _.get(league, "type");
		if(league_type 
			&& league_type === "head_to_head" 
			&& league.id === selected_league_id // account for new league loading in after selection
		){
			fetchLeagueRosters({
				league_id: selected_league_id,
				round: round_id
			});
		}
	}

	setSelectedRoundIdIfOutsideLeagueRounds(prev_props) {
		const { selected_league_id, league, rounds_by_id, setSelectedRoundId } = this.props;
		if (
			selected_league_id !== prev_props.selected_league_id
			|| league !== prev_props.league
			|| (!_.isEmpty(rounds_by_id) && _.isEmpty(prev_props.rounds_by_id))
		) {
			setSelectedRoundId(this.league_selected_round);
		}
	}
	handleFinalsModal() {
		this.setState({ is_finals_modal_open: !this.state.is_finals_modal_open });
	}

	/**
	 * Sometimes the active round which is the selected one by deafult will fall outside
	 * the leagues allowed rounds, in this case we must set the selected round to one the
	 * league is playing
	 */
	get league_selected_round() {
		const { selected_round_id, league } = this.props;

		if (selected_round_id && league) {
			const start_round = _.first(this.league_rounds).id;
			const end_round = _.last(this.league_rounds).id;

			if ( selected_round_id < start_round) {
				return start_round;
			}
			else if (selected_round_id > end_round) {
				return end_round;
			}

			return selected_round_id;
		}

		return  null;
	}
	get is_league_bye_round() {
		const {
			rounds_by_id,
			league,
			selected_round_id
		} = this.props;

		if (rounds_by_id && selected_round_id && league) {
			const round = rounds_by_id[selected_round_id];
			return Boolean(league.bye_rounds) ? false : round.is_bye;
		}

		return false;
	}

	get league_current_round_fixtures() {
		const { league, selected_round_id } = this.props;
		return selected_round_id ?
			_.get(league, `fixture.${selected_round_id}`, []): [];
	}

	get league_rounds() {
		const { rounds_ordered, league } = this.props;

		return getLeagueRounds(rounds_ordered, league);
	}

	get finals_rounds() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueRoundAliases(league, rounds_ordered);
	}

	get finals_names() {
		const {
			rounds_ordered,
			league
		} = this.props;

		return getLeagueFinalFullNames(league,  rounds_ordered);
	}

	getFinalsName(round: number) {
		return _.get(this.finals_names, round, "");
	}

	fetchLadder() {
		const { fetchLadder, selected_league_id, selected_round_id } = this.props;
		fetchLadder({
			league_id: selected_league_id,
			round: selected_round_id,
		});
	}

	fetchLeague() {
		const { fetchLeague, selected_league_id } = this.props;
		fetchLeague({
			id: selected_league_id
		});
	}

	renderFixturesHeading() {
		const { league, selected_round_id, setSelectedRoundId } = this.props;
		return (
			<PageSubTitleStyled>
				{
					this.getFinalsName(selected_round_id)
					|| `Round ${selected_round_id || 1} Fixtures`
				}
				{league && (
					<StyledButtonPrimaryLink to={`/classic/league/${league.id}/fixtures`}>
						View full Fixtures
					</StyledButtonPrimaryLink>
				)}
				<BelowTabletWrapper>
					<RoundSelector
						selected={selected_round_id}
						rounds={this.league_rounds}
						handleClick={setSelectedRoundId}
						round_aliases={this.finals_rounds}
					/>
				</BelowTabletWrapper>
			</PageSubTitleStyled>
		);
	}

	renderLadderHeading() {
		const { league, selected_round_id, last_complete_round = {} } = this.props;

		const ladder_round_id =
			getLeagueLadderRound(league, selected_round_id, last_complete_round);
		const is_finals = !_.isEmpty(this.getFinalsName(selected_round_id));

		return (
			<PageSubTitleStyled>
				{is_finals ? "Final" : `Round ${ladder_round_id}` } Ladder
				{league && (
					<StyledButtonPrimaryLink to={`/classic/league/${league.id}/ladder`}>
						View full Ladder
					</StyledButtonPrimaryLink>
				)}
			</PageSubTitleStyled>
		);
	}

	get shouldRenderNoFixturesMessage() {
		const {
			show_league_success,
			rounds_by_id,
		} = this.props;

		const empty_rounds = _.isEmpty(rounds_by_id);

		return (show_league_success && !empty_rounds && !this.league_current_round_fixtures.length);
	}

	get league_finished() {
		const { league, selected_round_id, rounds_by_id } = this.props;
		const round = _.get(rounds_by_id, selected_round_id);

		return hasLeagueFinished(league, round);
	}

	get winners() {
		const { league, ladder_teams_ordered, rounds_by_id, selected_round_id } = this.props;
		const round = _.get(rounds_by_id, selected_round_id);
		return getWinners(league, round, ladder_teams_ordered);
	}

	getPremier(consolation: boolean = false) {
		const { ladder_teams_ordered } = this.props;
		const winners = this.winners;

		return _.find(
			ladder_teams_ordered,
			{ id: consolation ? winners.consolation_premier : winners.premier }
		);
	}

	renderPremiers() {
		const premier = this.getPremier();
		return premier && <Premiers team={premier} />;
	}

	renderFixturesSection() {
		const {
			ladder_teams_by_id,
			league,
			rounds_with_matches,
			has_assistant_coach,
			coach_players_by_id,
			selected_round_id,
			actual_round,
			players_by_id,
			ladder_teams_ordered,
			user_id,
			
		} = this.props;
		const { is_finals_modal_open } = this.state;
		const is_finals = !_.isEmpty(this.getFinalsName(selected_round_id));
		return (
			<React.Fragment>
				{ this.renderPremiers() }
				{ this.renderFixturesHeading() }
				{is_finals &&
					<FinalsInfoButton onClick={this.handleFinalsModal}>
						How do finals work?
					</FinalsInfoButton>
				}
				
				{is_finals_modal_open &&
						<FinalsModal league={league} toggleModal={this.handleFinalsModal} />
				}
				<Fixtures
					league={league}
					ladder_teams_by_id={ladder_teams_by_id}
					ladder_teams_ordered={ladder_teams_ordered}
					rounds_with_matches={rounds_with_matches}
					has_assistant_coach={has_assistant_coach}
					coach_players_by_id={coach_players_by_id}
					selected_round_id={selected_round_id}
					is_league_bye_round={this.is_league_bye_round}
					is_league_no_fixtures={this.shouldRenderNoFixturesMessage}
					league_current_round_fixtures={this.league_current_round_fixtures}
					actual_round={actual_round}
					players_by_id={players_by_id}
					finals={this.finals_names}
					user_id={user_id}
					overview_page={true}
					compact
					is_classic
					exclude_top_heading
				/>
			</React.Fragment>
		);
	}

	renderLadderSection() {
		const {
			ladder_teams_ordered,
			ladder_teams_by_id,
			rounds_by_id,
			selected_round_id,
			user,
			league
		} = this.props;
		const { type: league_type } = league;
		ladder_teams_ordered.sort((a,b) => a.rank_selected_round - b.rank_selected_round);

		return (
			<React.Fragment>
				{this.renderLadderHeading()}
				{ league_type === "head_to_head" ?
					// $FlowFixMe
					<H2HLadder
						user_id={user.id}
						ladder_teams_ordered={ladder_teams_ordered}
						ladder_teams_by_id={ladder_teams_by_id}
						rounds_by_id={rounds_by_id}
						selected_round_id={selected_round_id}
						league={league}
						league_current_round_fixtures={this.league_current_round_fixtures}
						only_show_finals
						is_classic
						{...this.winners}
					/> :
					// $FlowFixMe
					<ClassicLadderOpen
						user={user}
						ladder_teams_ordered={ladder_teams_ordered}
						ladder_teams_by_id={ladder_teams_by_id}
						rounds_by_id={rounds_by_id}
						selected_round_id={selected_round_id}
						league={league}
						league_current_round_fixtures={this.league_current_round_fixtures}
						start_with={10}
						show_load_more={false}
						user_at_bottom
						{...this.winners}
					/>}
			</React.Fragment>
		);
	}

	renderMainSection() {
		const { selected_round_id, setSelectedRoundId, 
			league, favourite, user, clearFavourite, setFavourite } = this.props;
		const { type: league_type } = league;
		const current_league_object = {
			id: league.id,
			game: "classic",
			type: "league",
			user_id: user.id
		};
		const is_league_favourite = (favourite[user.id] === null || 
			favourite[user.id] === undefined) ? 
			false : favourite[user.id].id === league.id;
		return (
			<div>
				<PageHeaderWrapper>
					<TitleFavouriteWrapper>
						<PageTitle>
						League Overview for {league && league.name}
						</PageTitle>
						{is_league_favourite ? 
							<FavouriteButton 
								is_favourite
								onClick={() => clearFavourite({ user_id: user.id })}>
									Favourite
							</FavouriteButton> : 
							<FavouriteButton 
								onClick={() => setFavourite(current_league_object)}>
								Favourite
							</FavouriteButton> 
						}
					</TitleFavouriteWrapper>
				
					<AboveTabletWrapper>
						
						<RoundSelector
							selected={selected_round_id}
							rounds={this.league_rounds}
							handleClick={setSelectedRoundId}
							round_aliases={this.finals_rounds}
						/>
							
							
					</AboveTabletWrapper>
				</PageHeaderWrapper>
				

				{league_type === "head_to_head" && this.renderFixturesSection()}
				{this.renderLadderSection()}
				<AdFantasyCoach type="leaderboard" small_screen_only />
			</div>
		);
	}

	render() {

		return (
			<React.Fragment>
				<JsonFetcher
					fetch={["rounds", "coach_players", "players", "squads", "venues"]}
					interval={90000} /* Fetch new data once every 90s */
				/>

				<LeagueGameBar overview_page={true} />

				<AdsContainer>
					<Ad id="overview" />
				</AdsContainer>

				

				<PageContentWrapper className="playwright-mask-hidden">

					<TwoColumnLayout>
						{this.renderMainSection()}
						<StandardSidebar />
					</TwoColumnLayout>
				</PageContentWrapper>
				<MatchCentreBar />
				<Footer show_key />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state, props) => {
	const selected_league_id = props.match.params.league_id;
	return {
		selected_league_id,
		league: selectors.leaguesClassic.show.getLeague(state, props),
		is_pending_show: selectors.leaguesClassic.show.isPending(state),
		is_pending_ladder: selectors.leaguesClassic.ladder.isPending(state),
		ladder_teams_ordered: selectors.leaguesClassic.getOrderedLadderCalculated(state, props),
		ladder_teams_by_id: selectors.leaguesClassic.ladder.getById(state),
		show_league_success: state.leaguesClassic.show.success,
		user: selectors.getUser(state),
		has_assistant_coach: Boolean(selectors.getUser(state).assistant_coach),
		coach_players_by_id: state.players.coach_by_id,
		last_complete_round: selectors.rounds.getLastCompleteRound(state),
		actual_round: selectors.rounds.getActualRound(state),
		players_by_id: selectors.players.getExtendedPlayersById(state),
		rounds_with_matches: selectors.rounds.getRoundsWithMatches(state),
		favourite: state.uiGameSelection.favourite,
		user_id: state.user.data.id,
	};
};

const mapDispatchToProps = {
	fetchLeague: actions.showClassicLeague,
	fetchLadder: actions.ladderClassicLeague,
	fetchLeagueRosters: actions.getClassicLeagueRosters,
	setFavourite: actions.uiGameSelection.setFavourite,
	clearFavourite: actions.uiGameSelection.clearFavourite
};

export const LeagueOverview = compose(
	withRoundsSelector,
	withCoachesBox,
	connect(
		mapStateToProps,
		mapDispatchToProps
	)
)(LeagueOverviewPage);

export default LeagueOverview;