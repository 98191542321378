// @flow
import type { Saga } from "redux-saga";
import * as Sentry from "@sentry/browser";
import * as _ from "lodash";
import { call, put, takeEvery, takeLatest, select } from "redux-saga/effects";
import * as actions from "../../actions";
import * as selectors from "../../selectors";
import { Api, SagaError } from "../../../utils";

type Action = {
    payload: Object,
    type: string
};

const DEF_ERR_ACTION = {
	payload: {},
	type: "undefined"
};

const ERRORS_TO_FILTER = [
	"Please activate your account for AFL",
	"You are already joined this league",
	"This league is full",
	"Teams breakdown allowed only for user leagues",
	"League not found"
];


const filterErrors = (errors: Object[]) => {
	const found_error_to_filter = _.some(errors, error => {
		const error_text = _.get(error, "text");
		return _.includes(ERRORS_TO_FILTER, error_text);
	});


	// Filtered out the term "auth" in error texts, which sentry doesn't like
	_.forEach(
		errors, error => error.text = _.replace(
			_.get(error, "text"),
			new RegExp("auth", "i"),
			"A~th"
		)
	);

	const extra_sample = Math.random() > 0.25;

	return found_error_to_filter || extra_sample;
};

function* checkLeagueBeforeFetch(action, league_id) {
	const existing_leagues = yield select(selectors.leaguesClassic.getById);
	const existing_leagues_count = Object.keys(existing_leagues).length;
	const selected_league = Object.keys(existing_leagues).find(leagueID => 
		Number(leagueID)=== Number(league_id));
	if (existing_leagues_count === 0 || !selected_league) {
		const league_response = yield call(Api.leaguesClassic.show, { id: league_id });
		return Boolean(league_response.success);
	}
	
	return Boolean(selected_league);
}

const logError = (event: any, action: Action = DEF_ERR_ACTION, errors: Array<any>) => {
	const ignore  = filterErrors(errors);
	if (Sentry && !ignore) {
		const e = new SagaError(`ERROR performing: ${action.type}`, event);
		Sentry.withScope(scope => {
			scope.setTag("saga-error");
			scope.setLevel("debug");
			scope.setExtra("action", action);
			scope.setExtra("errors", errors);
			if (!_.isNil(event)) {
				scope.setExtra("full-saga-error", e);
				scope.setExtra("error-message", _.get(event, "message"));
				Sentry.captureException(event);
			}
			else {
				Sentry.captureException(_.get(errors, [0, "text"], e));
			}
		});
	}
};

export const createSagas = (API: Object): Object => {

	function* fetchLeagueRosters(action) {
		try {
			const { result, errors } =
                yield call(API.leaguesClassic.rosters, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.getClassicLeagueRostersFailed(errors[0].text));
			}
			const actual_round_id = yield select(selectors.rounds.getActualRound).id;

			const successPayload = { round_id: action.payload.round 
                || actual_round_id, result: result };
            
			yield put(actions.getClassicLeagueRostersSuccess(successPayload));
            
            
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* getCelebritiesSaga(action: Action): Saga<void> {
		try {
			const { result, errors } =
                yield call(Api.teamsClassic.get_celebrities, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.getCelebritiesFailed(errors[0].text));
			}
			else {
				yield put(actions.getCelebritiesSuccess({
					result,
				}));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* addCelebrityToClassicLeagueSaga(action: Action): Saga<void> {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.add_celebrity_teams, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.addCelebritiesToLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.addCelebritiesToLeagueSuccess({
					result,
				}));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* removeTeamFromLeagueSaga(action: Action): Saga<void> {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.remove_team, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.removeTeamFromLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.removeTeamFromLeagueSuccess({
					result,
				}));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* showClassicTeams(action: Action): Saga<void> {
		try {
			if(!_.get(action, "payload.league_id")){
				return;
			}
			const { result, errors } =
                yield call(Api.leaguesClassic.show_teams, { ...action.payload });

			if (!errors || errors.length) {
				logError(null, action, errors);
				yield put(actions.showTeamsClassicFailed(errors[0].text));
			}
			else {
				yield put(actions.showTeamsClassicSuccess({
					offset: action.payload.offset,
					result,
					league_id: _.get(action, "payload.league_id", "")
				}));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* howJoJoinClassicLeaguesSaga(action: Action): Saga<void> {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.show_to_join, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.howJoJoinClassicLeaguesFailed(errors[0].text));
			}
			else {
				yield put(actions.howJoJoinClassicLeaguesSuccess({
					offset: action.payload.offset,
					result,
				}));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* joinToClassicLeague(action) {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.joinTo, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.joinToClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.joinToClassicLeagueSuccess({
					team_id: result.team_id,
					league_id: result.id,
				}));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* showToJoinLoadMoreClassicLeagues(action) {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.show_to_join, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.showToJoinLoadMoreClassicLeaguesFailed(errors[0].text));
			}
			else {
				yield put(actions.showToJoinLoadMoreClassicLeaguesSuccess({
					offset: action.payload.offset,
					result,
				}));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* createClassicLeagueSaga(action: Action): Saga<void> {
		try {
			const { result, errors } = yield call(Api.leaguesClassic.create, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.createClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.createClassicLeagueSuccess(result));
				yield put( actions.globalRedirect( `/classic/league/${result.id}/invite/new`) );
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* showClassicLeagueSaga(action: Action): Saga<void> {
		try {
			const isExist = yield checkLeagueBeforeFetch(action, action.payload.id);
			if(!isExist){
				yield put( actions.globalRedirect(`/classic/team`));
				return;
			}
			const { result, errors } =
                yield call(Api.leaguesClassic.show, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.showClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.showClassicLeagueSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* showMyClassicLeaguesSaga(action: Action): Saga<void> {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.show_my, { ...action.payload, limit: 100 });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.showMyClassicLeaguesFailed(errors[0].text));
			}
			else {
				yield put(actions.showMyClassicLeaguesSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* updateClassicLeague(action: Action): Saga<void> {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.update, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.updateClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.updateClassicLeagueSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* inviteToClassicLeague(action) {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.inviteTo, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.inviteToClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.inviteToClassicLeagueSuccess(result));
			}
		}
		catch (event) {
			console.log(event);
			logError(event, action, []);
		}
	}

	function* ladderClassicLeague(action) {
		try {

			const isExist = yield checkLeagueBeforeFetch(action, action.payload.league_id);
			if(!isExist){
				yield put( actions.globalRedirect(`/classic/team`));
				return;
			}
			const { success, result, errors } =
                yield call(API.leaguesClassic.ladder, { ...action.payload });

			if (!success) {
				logError(null, action, errors);
				throw new Error(errors[0].text);
			}

			yield put(actions.ladderClassicLeagueSuccess({
				league_id: action.payload.league_id,
				offset: action.payload.offset,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== "test") {
				console.error(e);
			}
			logError(e, action, []);
			yield put(actions.ladderClassicLeagueFailed(e.message));
		}
	}

	function* ladderLoadMoreClassicLeague(action) {
		try {
			const { success, result, errors } =
                yield call(API.leaguesClassic.ladder, { ...action.payload });

			if (!success) {
				logError(null, action, errors);
				throw new Error(errors[0].text);
			}

			yield put(actions.ladderLoadMoreClassicLeagueSuccess({
				league_id: action.payload.league_id,
				offset: action.payload.offset,
				result,
			}));
		}
		catch (e) {
			if (process.env.NODE_ENV !== "test") {
				console.error(e);
			}
			logError(e, action, []);
			yield put(actions.ladderLoadMoreClassicLeagueFailed(e.message));
		}
	}


	function* leaveClassicLeague(action) {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.leave, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.leaveClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.leaveClassicLeagueSuccess(result));
			}
		}
		catch (event) {
			logError(event, action, []);
			console.log(event);
		}
	}

	function* removeClassicLeague(action) {
		try {
			const { errors } =
                yield call(Api.leaguesClassic.remove, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.removeClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.removeClassicLeagueSuccess(action.payload.id));
			}
		}
		catch (event) {
			logError(event, action, []);
			console.log(event);
		}
	}

	function* myListClassicLeagues(action) {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.myList, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.myListClassicLeaguesFailed(errors[0].text));
			}
			else {
				yield put(actions.myListClassicLeaguesSuccess(result));
			}
		}
		catch (event) {
			logError(event, action, []);
			console.log(event);
		}
	}

	function* regenerateListClassicLeagues(action) {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.regenerate_list);

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.regenerateListClassicLeaguesFailed(errors[0].text));
			}
			else {
				yield put(actions.regenerateListClassicLeaguesSuccess(result));
			}
		}
		catch (event) {
			logError(event, action, []);
			console.log(event);
		}
	}

	function* regenerateShowClassicLeague(action) {
		try {
			const { result, errors } =
                yield call(Api.leaguesClassic.regenerate_show, { ...action.payload });

			if (errors && errors.length) {
				logError(null, action, errors);
				yield put(actions.regenerateShowClassicLeagueFailed(errors[0].text));
			}
			else {
				yield put(actions.regenerateShowClassicLeagueSuccess(result));
			}
		}
		catch (event) {
			logError(event, action, []);
			console.log(event);
		}
	}



	function* watch() {
		yield takeLatest(actions.howJoJoinClassicLeagues, howJoJoinClassicLeaguesSaga);
		yield takeLatest(actions.joinToClassicLeague, joinToClassicLeague);
		yield takeLatest(
			actions.showToJoinLoadMoreClassicLeagues, showToJoinLoadMoreClassicLeagues
		);
		yield takeLatest(actions.createClassicLeague, createClassicLeagueSaga);
		yield takeLatest(actions.showClassicLeague, showClassicLeagueSaga);
		yield takeLatest(actions.inviteToClassicLeague, inviteToClassicLeague);
		yield takeEvery(actions.ladderClassicLeague, ladderClassicLeague);
		yield takeEvery(actions.ladderLoadMoreClassicLeague, ladderLoadMoreClassicLeague);
		yield takeLatest(actions.updateClassicLeague, updateClassicLeague);
		yield takeLatest(actions.leaveClassicLeague, leaveClassicLeague);
		yield takeLatest(actions.myListClassicLeagues, myListClassicLeagues);
		yield takeLatest(actions.showMyClassicLeagues, showMyClassicLeaguesSaga);
		yield takeLatest(actions.removeClassicLeague, removeClassicLeague);
		yield takeLatest(actions.regenerateListClassicLeagues, regenerateListClassicLeagues);
		yield takeEvery(actions.regenerateShowClassicLeague, regenerateShowClassicLeague);
		yield takeLatest(actions.addCelebritiesToLeague, addCelebrityToClassicLeagueSaga);
		yield takeLatest(actions.getCelebrities, getCelebritiesSaga);
		yield takeLatest(actions.removeTeamFromLeague, removeTeamFromLeagueSaga);
		yield takeEvery(actions.getClassicLeagueRosters, fetchLeagueRosters);
		yield takeLatest(actions.showTeamsClassic, showClassicTeams);
	}

	return {
		getCelebritiesSaga,
		addCelebrityToClassicLeagueSaga,
		howJoJoinClassicLeaguesSaga,
		joinToClassicLeague,
		showToJoinLoadMoreClassicLeagues,
		createClassicLeagueSaga,
		showClassicLeagueSaga,
		showMyClassicLeaguesSaga,
		inviteToClassicLeague,
		ladderClassicLeague,
		ladderLoadMoreClassicLeague,
		updateClassicLeague,
		leaveClassicLeague,
		regenerateListClassicLeagues,
		regenerateShowClassicLeague,
		fetchLeagueRosters,
		showClassicTeams,
		watch
	};
};

export default createSagas;