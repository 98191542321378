// @flow
import React, { Fragment } from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { SEASON_YEAR } from "../../modules/constants";
import colors from "../../assets/css/colors";
import { below, above } from "../../assets/css/media";
import genius_logo from "../../assets/images/geniusLogo.png";
import telstra_logo from "../../assets/images/footer-logo-telstra.png";
import toyota_logo from "../../assets/images/toyota-black-logo.png";

import IconCircled from "../IconCircled";
import AddPlayer from "../Icons/AddPlayer";
import Trade from "../Icons/Trade";
import Delist from "../Icons/Delist";
import Swap from "../Icons/Swap";
import Captain from "../Icons/Captain";
import ViceCaptain from "../Icons/ViceCaptain";
import Emergency from "../Icons/Emergency";

import Available from "../Icons/Available";
import Injured from "../Icons/Injured";
import NotSelected from "../Icons/NotSelected";
import ToolTip from "../Icons/ToolTip";
import SelectedEmergency from "../Icons/SelectedEmergency";
import Bye from "../Icons/Bye";
import InjurySub from "../Icons/InjurySub";
import { DraftBar } from "../";
import FooterKeytItem from "./FooterKeytItem";
import FooterNav from "./FooterNav";




const FooterWrapper = styled.footer`
	width: 100%;
	background: ${colors.primary.dark};
	box-sizing: border-box;
	padding: 30px 0 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const FooterContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	max-width: 1280px;
	box-sizing: border-box;
	padding: 0 20px;
	${props => !props.show_key && `
		justify-content: flex-start;
		& > :nth-child(2) {
			margin-right: 30px;
		}
	`};
	${below.tablet`
		flex-direction: column;
	`};
`;

const Heading = styled.h3`
	font-size: 18px;
	line-height: 1;
	border-bottom: 1px solid #D8D8D8;
	padding-bottom: 0.5em;
	text-transform: uppercase;
	font-weight: 400;
	margin-bottom: 1.44em;
`;

const FooterKey = styled.section`
	display: none;
	width: calc(50% - 20px);
	section {
		display: flex;
		flex-direction: row;
	}

	${props => props.show_key && above.tablet`
		display: block;
	`};
`;

const FooterColumn = styled.div`
	width: calc(25% - 20px);
	a {
		display: block;
		margin-bottom: 10px;
	}

	${below.tablet`
		width: 100%;
		& + & {
			margin-top: 30px;
		}
	`};
`;

const KeyList = styled.ul`
	padding: 0;
	width: 40%;
`;

const FooterLogoWhiteBack = styled.div`
  height: 70px;
  width: 155px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  img{
    width: 70px;
  }
`;

const FooterLower = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	font-size: 12px;
	line-height: 1;
	padding: 0 20px 18px;
	margin-top: 100px;
	font-family: 'TitilliumUpright';
	max-width: 1280px;
	box-sizing: border-box;
	.genius-logo{
		display: flex;
		align-items: center;
		width: 100px;
		height: 35px;
		span{
			font-family: SourceSansPro;
			color: white;
			font-size: 12px;
			margin-right: 10px;
		}
		${below.tablet`
			margin-top: 20px;
		`}
	}
	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
	&:before {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		background: currentColor;
		margin-bottom: 18px;
	}

	${below.tablet`
		flex-direction: column;
		text-align: center;
		padding-bottom: 35px;

		span {
			display: block;
			position: relative;
			max-width: 250px;
			margin: 30px auto;
			line-height: 1.5;
		}
	`}
`;

type Props = {
	show_key?: boolean,
	location: {
		pathname: string
	}
}

const FooterComponent = ({ show_key, location }: Props) => {
	const is_classic = _.get(location, "pathname", "").includes("classic");
	const is_draft = _.get(location, "pathname", "").includes("draft");
	const injurySubText = "This player is listed as an Injury Substitute for this round.";

	return (
		<Fragment>
			<FooterWrapper>
				<FooterContainer show_key={show_key}>
					<FooterKey show_key={show_key}>
						<Heading>
							Key — Gameplay & Availability
						</Heading>
						<section>
							<KeyList>
								<FooterKeytItem
									description="Add player"
									icon={<IconCircled><AddPlayer /></IconCircled>}
									tooltip="Add this player to your team."
								/>
								<FooterKeytItem
									description="Trade player"
									icon={<IconCircled><Trade /></IconCircled>}
									tooltip="This player is available to trade."
								/>
								<FooterKeytItem
									description={is_classic ? "Remove player" : "Delist player"}
									icon={<IconCircled><Delist /></IconCircled>}
									tooltip={
										is_classic ?
											"This player can be removed from your team." :
											"This player can be delisted from your team."
									}
								/>
								<FooterKeytItem
									description="Switch player"
									icon={<IconCircled><Swap /></IconCircled>}
									tooltip="This player can be substituted to or from your bench."
								/>
								<FooterKeytItem
									description="Captain"
									icon={
										<IconCircled background={colors.player.captain}>
											<Captain />
										</IconCircled>
									}
									tooltip="This player is the assigned Captain of your team."
								/>
								<FooterKeytItem
									description="Vice Captain"
									icon={
										<IconCircled background={colors.player.vice_captain}>
											<ViceCaptain />
										</IconCircled>
									}
									tooltip="This player is the assigned Vice Captain of your team."
								/>
								<FooterKeytItem
									description="Emergency"
									icon={
										<IconCircled background={colors.player.emergency}>
											<Emergency />
										</IconCircled>
									}
									tooltip="This player is one of the
									assigned Emergencies of your team."
								/>
							</KeyList>
							<KeyList>
								<FooterKeytItem
									description="Selected on field"
									icon={
										<IconCircled background={colors.player.available}>
											<Available />
										</IconCircled>
									}
									tooltip="This player is listed as available to play."
								/>
								<FooterKeytItem
									description="Reported as injured"
									icon={
										<IconCircled background={colors.player.injured} >
											<Injured />
										</IconCircled>
									}
									tooltip="This player is reported to be injured."
								/>
								<FooterKeytItem
									description="Not selected"
									icon={
										<IconCircled background={colors.player.not_selected} >
											<NotSelected />
										</IconCircled>
									}
									tooltip="This player is not listed as available to play."
								/>
								<FooterKeytItem
									description={"Selection is uncertain"}
									icon={
										<IconCircled background={colors.player.uncertain} >
											<ToolTip />
										</IconCircled>
									}
									tooltip={
										"Either this player's team has not been selected, or "
										+ "they have been selected on their team's extended bench."
									}
								/>
								<FooterKeytItem
									description="Selected as an emergency"
									icon={
										<IconCircled background={colors.player.emergency}>
											<SelectedEmergency />
										</IconCircled>
									}
									tooltip="This player is listed as an emergency for this round."
								/>
								<FooterKeytItem
									description="Bye round"
									icon={
										<IconCircled background={colors.player.bye} >
											<Bye />
										</IconCircled>
									}
									tooltip="This player is on a bye round."
								/>
								<FooterKeytItem
									description="Injury Substitute"
									icon={
										<IconCircled background={colors.player.medical_sub} >
											<InjurySub />
										</IconCircled>
									}
									tooltip={injurySubText}
								/>
							</KeyList>
						</section>
					</FooterKey>
					<FooterColumn>
						<Heading>
							Official Partners
						</Heading>
						<a href="https://www.telstra.com.au/"
							target="_blank"
							rel="noopener noreferrer"
							title="Telstra">
							<img src={telstra_logo} alt="Telstra Logo"/>
						</a>
						<FooterLogoWhiteBack>
							<img src={toyota_logo} alt="Toyota Logo"/>
						</FooterLogoWhiteBack>
					</FooterColumn>
					<FooterColumn>
						<Heading>
							Site Map
						</Heading>
						<FooterNav />
					</FooterColumn>
				</FooterContainer>
				<FooterLower>
					<p>
						Copyright ©{SEASON_YEAR} AFL. All Rights Reserved &nbsp;&nbsp;
					</p>
					<a href="https://geniussports.com/genius-marketing-suite/genius-games/"
						target="_blank"
						rel="noopener noreferrer"
						title="Powered by Genius Sports"
						className="genius-logo"
					>
						<img src={genius_logo} alt="Powered by Genius Sports" />
					</a>
				</FooterLower>
			</FooterWrapper>
			{is_draft ? <DraftBar /> : null}
		</Fragment>
	);
};

FooterComponent.displayName = "Footer";

export const Footer = withRouter(FooterComponent);

export default Footer;