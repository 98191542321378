// @flow
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import get from "lodash/get";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import isAuthenticated from "../../utils/auth/isAuthenticated";
import {
	selectedDraftLeagueSelector,
	selectedClassicLeagueSelector
} from "../../modules/selectors/uiMenu/getSelectedLeague";



import { below } from "../../assets/css/media";
import { header_height } from "../../assets/css/vars";

import Nav from "../Nav";
import { isOffSeason } from "../../utils";

const OFF_SEASON = isOffSeason();

const MenuWrapper = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	${below.tablet`
		position: absolute;
		top: ${header_height}px;
		left: 0;
		z-index: 150;
		height: 100vh;
		width: 100vw;
		background: #fff;
		flex-direction: column;
		justify-content: flex-start;
		opacity: 0;
		visibility: hidden;
		box-size: border-box;
		overflow: auto;
		overflow-x: hidden;
		overflow-y: auto;
		pointer-events: none;
		left: -100vw;
		${props => props.menu_open && `
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
			left: 0;
		`}
	`};
`;

const Left = styled.div`
`;

const Right = styled.div`
	margin-left: auto;
	${below.tablet`
		width: 100%;
		padding-bottom: 100px;
	`};
`;

type Location = {
	hash: string,
	key: string,
	pathname: string,
	search: string
}

type Props = {
	fetchMyDraftLeagues: typeof actions.leagueDraft.showMyDraftLeagues,
	fetchMyClassicLeagues: typeof actions.showMyClassicLeagues,
	closeHamburgerMenu: typeof actions.uiMenu.closeHamburgerMenu,
	setSelectedClassicLeague: Function,
	setPathname: Function,
	my_draft_leagues: Array<Object>,
	location: Location,
	is_authorized: boolean,
	menu_open?: boolean,
	right_menu_object: Array<Object>,
	left_menu_object: Array<Object>,
	favourite_league: Object,
	selected_classic_league_id: string,
	my_classic_leagues_ids: Array<number>,
	my_draft_leagues: Array<number>,
	pathname: string,
	selected_draft_league: Object,
	selected_classic_league: Object,
	rounds_pending: boolean,
	closeSubModal: Function,
	openSubModal: Function,
	is_contact_page?: boolean
}

// const statCentreNav = 
// 	{
// 		to: '/classic/stats-centre',
// 		title: "Stats Centre",
// 	};


type State = {
	mobile_nav_top_offset: number
}
class Menu extends React.Component<Props, State> {
	state = {
		mobile_nav_top_offset: 50
	};

	componentDidMount() {
		const {
			is_authorized,
			setPathname,
		} = this.props;


		if (is_authorized) {
			this.fetchLeagues();
		}

		if(get(this.props, "location.pathname")) {
			setPathname(get(this.props, "location.pathname"));
		}

		_.bindAll(this, ["setSelectedClassicLeague", "handleWindowScroll"]);

		this.setSelectedClassicLeague();
		window.addEventListener("scroll", this.handleWindowScroll);

	}

	shouldComponentUpdate(next_props) {
		return _.some([
			!next_props.rounds_pending && this.props.rounds_pending,
			!_.isEqual(next_props.selected_draft_league, this.props.selected_draft_league),
			!_.isEqual(next_props.selected_classic_league, this.props.selected_classic_league),
			next_props.location.pathname !== this.props.location.pathname,
			next_props.pathname !== this.props.pathname,
			next_props.menu_open !== this.props.menu_open
		]);
	}

	componentDidUpdate(prevProps) {
		this.setSelectedClassicLeague();
		if(get(this.props, "location.pathname") !== get(prevProps, "location.pathname")) {
			this.props.setPathname(get(this.props, "location.pathname"));
			this.props.closeHamburgerMenu();
			window.scrollTo(0, 0);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleWindowScroll);
	}

	handleWindowScroll(e) {
		if(window.pageYOffset < header_height) {
			const mobile_nav_top_offset = header_height - window.pageYOffset;
			this.setState({ mobile_nav_top_offset });
		}
		else {
			this.setState({ mobile_nav_top_offset: 0 });
		}
	}

	setSelectedClassicLeague() {
		const {
			favourite_league,
			setSelectedClassicLeague,
			selected_classic_league_id,
			my_classic_leagues_ids,
		} = this.props;

		// If no classic league selected check for fav.
		if(
			selected_classic_league_id === null &&
			get(favourite_league, "game") === "classic" &&
			get(favourite_league, "type") === "league"
		) {
			setSelectedClassicLeague(get(favourite_league, "id"));
		}
		// if no classic league selected set first league
		else if(selected_classic_league_id === null && my_classic_leagues_ids.length > 0) {
			setSelectedClassicLeague(my_classic_leagues_ids[0]);
		}
	}

	fetchLeagues() {
		const {
			my_draft_leagues,
			my_classic_leagues_ids
		} = this.props;
		const isClassic = this.props.location.pathname.includes("classic");
		const isDraft = this.props.location.pathname.includes("draft");
		const draftLeaguesExist = my_draft_leagues.length > 1;
		const classicLeaguesExist = my_classic_leagues_ids.length > 1;
		if(isClassic && !classicLeaguesExist){
			this.props.fetchMyClassicLeagues({ limit: 50 });
		}
		if(isDraft && !draftLeaguesExist){
			this.props.fetchMyDraftLeagues();
		}
		
	}

	render() {
		const { menu_open, left_menu_object, 
			right_menu_object, openSubModal, closeSubModal,  
			is_authorized  } = this.props;
		let leftMenuItems = left_menu_object;
		if(!is_authorized && !isOffSeason()){
			const inSeasonItems = [
				{
					to: "/contact-us",
					title: "Contact Us"
				},
				{
					to: "/classic/help/prizes",
					title: "Prizes"
				}
			];
			leftMenuItems = [...leftMenuItems, ...inSeasonItems];
		}
		if(!is_authorized && isOffSeason()){
			const offSeasonItems = [
				{
					to: "/contact-us",
					title: "Contact Us"
				},
				{
					to: "/off-season/stats-centre",
					title: "Stats Centre"
				}
			];
			leftMenuItems = [...leftMenuItems, ...offSeasonItems];
		}
		
		return (
			<MenuWrapper
				menu_open={menu_open}
				mobile_nav_top_offset={this.state.mobile_nav_top_offset}
				id="menu-wrapper"
			>
				<Left>
					<Nav items={leftMenuItems} 
						openSubModal={openSubModal} closeSubModal={closeSubModal}/>
				</Left>
				{!OFF_SEASON && (
					<Right>
						<Nav items={right_menu_object} />
					</Right>
				)}

			</MenuWrapper>
		);
	}
}

const mapStateToProps = (state, props) => {
	const user_id = _.get(state, "user.data.id", 0);
	const favourite_leagues = state.uiGameSelection.favourite || {};
	return {
		is_authorized: isAuthenticated(),
		menu_open: state.uiMenu.open,
		right_menu_object: selectors.uiMenu.getRightNav(state, props),
		left_menu_object: selectors.uiMenu.getLeftNav(state, props),
		favourite_league: favourite_leagues[user_id],
		selected_classic_league_id: state.uiSelectedLeague.selected_classic,
		my_classic_leagues_ids: state.leaguesClassic.show_my.ordered_ids,
		my_draft_leagues: state.leagues.show_my.ordered_ids,
		pathname: state.uiMenu.pathname,
		selected_draft_league: selectedDraftLeagueSelector(state),
		selected_classic_league: selectedClassicLeagueSelector(state),
		rounds_pending: state.rounds.is_pending,
	};
};

const mapDispatchToProps = {
	fetchMyDraftLeagues: actions.leagueDraft.showMyDraftLeagues,
	fetchMyClassicLeagues: actions.showMyClassicLeagues,
	setPathname: actions.uiMenu.setPathname,
	setSelectedClassicLeague: actions.uiSelectedLeague.setSelectedClassicLeague,
	closeHamburgerMenu: actions.uiMenu.closeHamburgerMenu
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Menu));