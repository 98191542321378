// @flow
import * as React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import _ from "lodash";
import { compose } from "redux";
import type { TRoundWithMatches } from "../../modules/types";
import * as selectors from '../../modules/selectors';
import colors from "../../assets/css/colors";
import { above, below } from "../../assets/css/media";
import { header_height } from "../../assets/css/vars";
import { TPLeague } from "../../modules/types";
import * as actions from "../../modules/actions";
import { getNumberOfNotifications } from "../../modules/selectors/league";
import { selectedClassicLeagueSelector, selectedDraftLeagueSelector } from
"../../modules/selectors/uiMenu/getSelectedLeague";
import withConfirmModal from "../utils/withConfirmModal";
import { getLockoutInfo } from "../../helpers/rounds";
import { isAuthenticated } from "../../utils/auth";
import { isAllTrue } from "../../helpers";
import LockoutCountdownTimer from "./LockoutCountdownTimer";
import YourTeamDetails from "./YourTeamDetails";
import Notifications from "./Notifications";
import { game_types, getGameType } from "./utils/gameType";


const Container = styled.div`
	background-color: ${colors.form.base};
	box-shadow: 0 4px 4px rgba(0, 0, 0, .2);

	/* Give it a fixed height so other stickies can sit under it */
	height: 80px;

	@supports(position: sticky) {
		& {
			position: sticky;
			top: ${header_height}px;
			left: 0;
			z-index: 97;
		}
	}

	${below.tablet`
		height: 40px;
		display: flex;
		align-items: center;
		box-shadow: none;
		background-color: #fff
	`}
`;

const YourTeamDetailsContainer = styled.div`
	flex: 1 2 auto;
	display: flex;
`;

const InnerContainer = styled.div`
	box-sizing: border-box;
	width: 100%;
	max-width: 1280px;
	margin: 0 auto;
	padding: 0px 10px;

	display: flex;

	${above.tablet`
		min-height: 80px;
		padding: 10px 10px 10px 10px;
	`}
`;

type Props = {
	actual_round: TRoundWithMatches,
	league: TPLeague,
	league_is_pending: boolean,
	game_type: ?'classic' | 'draft',
	show_notifications: boolean,
	num_notifications: number,
	selected_league_id: number,
	fetchDraftLeague: Function,
	fetchDraftLadder: Function,
	fetchTrades: Function,
	fetchWaiverRequests: Function,
	fetchClassicLeague: Function,
	fetchClassicLadder: Function,
	fetchClassicLeagueRosters: Function,
	fetchPlayers: Function,
	fetchCoachPlayers: Function,
	is_ladder_empty: boolean,
	is_coach_players_empty: boolean,
	is_players_empty: boolean,
	is_rounds_empty: boolean,
	fetchRounds: Function,
	overview_page: boolean,
	no_fetch: boolean,
	fetchClassicTeam: Function,
	showConfirmModal: Function,
	hideConfirmModal: Function,
	matchup_page: boolean,
	is_authorized: boolean
}

class LeagueGameBarComponent extends React.Component<Props> {
	componentDidMount() {
		this.fetchData();
	}
	componentDidUpdate(prev_props: Props) {
		const old_league_id = prev_props.selected_league_id;
		const league_id = this.props.selected_league_id;
		if (this.props.game_type !== prev_props.game_type 
			|| old_league_id !== league_id
			|| (!_.isEmpty(this.props.league) && _.isEmpty(prev_props.league))
		) {
			this.fetchData();
		}
	}
	fetchData() {
		const { game_type, no_fetch } = this.props;

		if(no_fetch){
			this.checkFetchData();
		}
		if (game_type === "classic") {
			this.fetchClassicData();
		}
		if (game_type === "draft") {
			this.fetchDraftData();
		}
	}

	fetchDraftData() {
		const {
			selected_league_id,
			fetchDraftLeague,
			fetchDraftLadder,
			fetchWaiverRequests,
			fetchTrades,
			matchup_page, 
			no_fetch
		} = this.props;
		if(matchup_page || no_fetch){
			return;
		}
		fetchDraftLeague({ id: selected_league_id });
		fetchDraftLadder({ league_id: selected_league_id });
		fetchTrades({ league_id: selected_league_id });
		fetchWaiverRequests({ league_id: selected_league_id });
	}


	checkFetchData() {
		const {
			is_coach_players_empty,
			is_players_empty,
			game_type,
			is_ladder_empty,
			is_rounds_empty,
			selected_league_id
		} = this.props;
		const is_classic = game_type === "classic";
		if(is_rounds_empty){
			this.props.fetchRounds();
		}
		if(is_players_empty){
			this.props.fetchPlayers();
		}
		if(is_coach_players_empty){
			this.props.fetchCoachPlayers();
		}
		if(isAllTrue([
			is_classic,
			is_ladder_empty,
			Boolean(selected_league_id)
		])){
			this.props.fetchClassicLadder({ league_id: selected_league_id });
		}
		if(isAllTrue([
			!is_classic,
			is_ladder_empty,
			Boolean(selected_league_id)
		])){
			this.props.fetchDraftLadder({league_id: selected_league_id});
		}
	}

	fetchClassicData() {
		const { 
			selected_league_id,
			fetchClassicLeague,
			fetchClassicLadder,
			fetchClassicLeagueRosters,
			league,
			overview_page,
			matchup_page,
			no_fetch
		} = this.props;
		const league_type = _.get(league, "type");
		if(no_fetch){
			return;
		}

		if (selected_league_id) {
			fetchClassicLadder({ league_id: selected_league_id });
			if(!overview_page){
				fetchClassicLeague({ id: selected_league_id });
			}
			// Only need rosters in H2H leagues
			if (!_.isEmpty(league) && league_type !== "open" && !matchup_page) {
				fetchClassicLeagueRosters({ 
					league_id: selected_league_id, 
				});
			}
			
			
		}
	}
	getLockoutInfo() {
		const { actual_round, league, game_type } = this.props;
		const is_rolling = Boolean(_.get(league, "lockout", false)) && game_type === "draft";

		return getLockoutInfo(actual_round, is_rolling);
	}
	render() {
		const {
			actual_round,
			league,
			league_is_pending,
			show_notifications,
			num_notifications,
			showConfirmModal,
			hideConfirmModal,
			is_authorized
		} = this.props;
		const league_id = _.get(league, "id");

		if(!is_authorized){
			return null;
		}

		return (
			<Container className="playwright-mask-hidden">
				<InnerContainer>
					<LockoutCountdownTimer lockout_info={this.getLockoutInfo()} />
					<YourTeamDetailsContainer>
						<YourTeamDetails
							league={league}
							league_is_pending={league_is_pending}
							actual_round={actual_round}
							showConfirmModal={showConfirmModal}
							hideConfirmModal={hideConfirmModal}
						/>
					</YourTeamDetailsContainer>
					<Notifications
						to={`/draft/league/${league_id}/transactions`}
						count={num_notifications}
						show_notifications={show_notifications}
					/>
				</InnerContainer>
			</Container>
		);
	}
}

const getGameTypeSelector = (game_type, selectors) => game_type
	? selectors[game_type]
	: null;
const getStateUsingSelector = (state, selector) => selector
	? selector(state)
	: null;

const game_type_league_selectors = {
	[game_types.CLASSIC]: selectedClassicLeagueSelector,
	[game_types.DRAFT]: selectedDraftLeagueSelector
};

const game_type_league_is_pending_selectors = {
	[game_types.CLASSIC]: state => state.leaguesClassic.show.is_pending,
	[game_types.DRAFT]: state => state.leagues.show.is_pending,
};

const game_type_ladder_teams_by_id_selectors = {
	[game_types.CLASSIC]: selectors.leaguesClassic.ladder.getById,
	[game_types.DRAFT]: selectors.leagues.ladder.getById
};


const mapStateToProps = (state, props) => {
	const { location: { pathname } } = props;
	const game_type = getGameType(pathname);
	
	const game_type_league_selector = game_type ? game_type_league_selectors[game_type] : null;
	const game_type_league_is_pending_selector = game_type
		? game_type_league_is_pending_selectors[game_type]
		: null;
	const selected_league = game_type_league_selector ? game_type_league_selector(state): null;
	const league_is_pending = game_type_league_is_pending_selector
		? game_type_league_is_pending_selector(state)
		: null;
	// ladder_teams_by_id
	const game_type_ladder_teams_by_id_selector =
		getGameTypeSelector(game_type, game_type_ladder_teams_by_id_selectors);
	const ladder_teams_by_id = getStateUsingSelector(state, game_type_ladder_teams_by_id_selector);

	const is_ladder_empty = _.isEmpty(ladder_teams_by_id);
	const rounds_ordered = selectors.rounds.getRounds(state);
	const is_rounds_empty = _.isEmpty(rounds_ordered);

	const selected_league_id = _.get(
		state.uiSelectedLeague, 
		game_type === game_types.CLASSIC ? "selected_classic" : "selected"
	);

	return {
		actual_round: selectors.rounds.getActualRound(state),
		league: selected_league,
		league_is_pending,
		game_type,
		show_notifications: game_type === "draft",
		num_notifications: getNumberOfNotifications(state),
		selected_league_id,
		is_authorized: isAuthenticated(),
		is_coach_players_empty: _.isEmpty(state.players.coach_by_id),
		is_players_empty: _.isEmpty(state.players.by_id),
		is_ladder_empty,
		is_rounds_empty
	};
};

const mapDispatchToProps = {
	fetchDraftLeague: actions.leagueDraft.showDraftLeague,
	fetchDraftLadder: actions.leagueDraft.ladderDraftLeague,
	fetchTrades: actions.teamsDraft.fetchTrades,
	fetchWaiverRequests: actions.leagueDraft.fetchWaiverRequests,
	fetchClassicLeague: actions.showClassicLeague,
	fetchClassicLadder: actions.ladderClassicLeague,
	fetchClassicLeagueRosters: actions.getClassicLeagueRosters,
	fetchClassicTeam: actions.fetchMyClassicTeam,
	fetchPlayers: actions.fetchPlayers,
	fetchCoachPlayers: actions.fetchCoachPlayers,
	fetchRounds: actions.fetchRounds
};

export const LeagueGameBar = compose(
	withRouter,
	withConfirmModal,
	connect(mapStateToProps, mapDispatchToProps)
)(LeagueGameBarComponent);

export default LeagueGameBar;