// @flow
import * as React from "react";
import { Field, reduxForm } from "redux-form";
import styled from "styled-components";

import { PreregistrationValidate } from "../../utils/FormValidations";

import { SimpleFormField } from "../../components";

import { below } from "../../assets/css/media";
import ButtonPrimaryStyled from "./buttonPrimaryStyled";
import LinkStyled from "./linkStyled";

const LoginFormStyled = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h5{
		height: 16px;
		width: 100%;
		color: #FFFFFF;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
	}
`;

const FormItem = styled.div`
	display: flex;
	width: 300px;
	margin: 0 auto;
	padding: 0;

	input{
		text-align: left;
		height: 40px;
		font-family: "SourceSansPro"; 
	}
	${below.phone`
		width: 335px;
	`}
`;

const Description = styled.div`
	max-width: 370px;
	font-family: "SourceSansPro";
	color: #FFFFFF;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	margin-bottom: 20px;
	${below.desktop`
		max-width: 600px;
		font-size: 16px;
	`};
`;

const RankingsDescription = styled(Description)`
	max-width: 100%;
	margin-top: 30px;
`;

type Props = {
	handleSubmit: Function,
	form_sending: boolean,
}

const OffSeasonFormComponent = ({ handleSubmit, form_sending }: Props) => (
	<LoginFormStyled onSubmit={handleSubmit}>
		{!form_sending ? (
			<div>
				<Description>
					We’ll be back in 2024! Make sure you
					pre-register to receive early announcements.
				</Description>
				<FormItem>
					<Field
						type='text'
						name='first_name'
						autocomplete="username"
						placeholder='Full Name'
						max_length={120}
						component={SimpleFormField}
					/>
				</FormItem>
				<FormItem>
					<Field
						type='email'
						name='email'
						placeholder='Email'
						component={SimpleFormField}
						className='form-item'
						autocomplete="current-password"
					/>
				</FormItem>

				<ButtonPrimaryStyled>
					Pre-Register
				</ButtonPrimaryStyled>
			</div>
		) : (
			<Description>
				Thank you to all Fantasy coaches for your efforts this year.
				It’s now time to rest up, kick back and
				enjoy your end of season trips.
				See you in 2024!
			</Description>
		)}

		<RankingsDescription>
			Why not look back at how you performed this season?
		</RankingsDescription>
		<LinkStyled to="/classic/rankings">
			Rankings
		</LinkStyled>
	</LoginFormStyled>
);
export const OffSeasonForm = reduxForm({
	form: "pre_register",
	validate: PreregistrationValidate,
})(OffSeasonFormComponent);

export default OffSeasonForm;