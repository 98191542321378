// @flow
import styled from "styled-components";

import colors from "../../assets/css/colors";

export const BoxLeague = styled.div`
	position: relative;
	box-sizing: border-box;
	width: 100%;
	background-color: ${colors.form.base};
	border: 1px solid ${colors.secondary.lightGrey};
	border-radius: 5px;
	padding: 20px;
	margin: 40px 0 10px;
	transition: box-shadow 0.3s, background-color 0.3s;

	${({ no_hover }) => !no_hover && `
		&:hover {
			background-color: #FFFFFF;
			box-shadow: 0px 0px 10px 0px rgba(38,36,36,0.5);
		}
	`}
`;

export const BoxLeagueNameText = styled.div`
	font-size: 20px;
	font-weight: 500;
	text-align: center;
	line-height: 1.2;
	color: ${colors.primary.primary};
	margin-bottom: 10px;
`;

export const BoxLeagueNameSubText = styled.div`
	font-size: 15px;
	font-weight: 500;
	text-align: center;
	line-height: 1.2;
	color: ${colors.secondary.accentGrey};
	margin-bottom: 20px;
`;

export const BoxInner = styled.div`
	position: relative;
	max-width: 380px;
	margin: 0 auto;
`;

export default BoxLeague;