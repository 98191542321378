// @flow
export const clubColors = {
	"10": "#002A5C",    // Adelaide Crows
	"20": "#8B0042",    // Brisbane Lions
	"30": "#002E51",    // Carlton
	"40": "#000000",    // Collingwood
	"50": "#000000",    // Essendon
	"60": "#341356",    // Fremantle
	"70": "#002958",    // Geelong Cats
	"1000": "#E41E0A",  // Gold Coast Suns
	"1010": "#FF7900",  // GWS Giants
	"80": "#562500",    // Hawthorn
	"90": "#061A33",    // Melbourne
	"100": "#0039A3",   // North Melbourne
	"110": "#0297B4",   // Port Adelaide
	"120": "#000000",   // Richmond
	"130": "#E00034",   // St Kilda
	"140": "#0039A6",   // Western Bulldogs
	"150": "#002A5C",   // West Coast Eagles
	"160": "#D11F2E",   // Sydney Swanss
};

export default clubColors;