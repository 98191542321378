
// @flow
import * as React from "react";
import _, { get, isEmpty } from "lodash";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
// import PubNubReact from "pubnub-react";
import type { TPlayer, TRootStore, TDraftTeamsById, TDraftUserTeam } from "../../../modules/types";
import { isMobile, isBelowTablet } from "../../../utils";
import { renderTableHeaderCell } from "../../../helpers/tables";

import * as actions from "../../../modules/actions";
import * as selectors from "../../../modules/selectors";
import {
	AdsContainer,
	Aside,
	ButtonLoadMore,
	ConfirmationModal,
	CountdownTimer,
	DraftFilters,
	DraftLayout,
	DraftOrderWidget,
	DraftTableHeader,
	DraftTeamListOrderIndicator,
	DraftTimerWrapper,
	DraftTitleContainer,
	Expander,
	Exist,
	Footer,
	PageTitle,
	Preloader,
	TableHead,
	ThIgnore,
	ThStat,
	TopBarWrapper,
	withDraftCore,
	withDraftPlayerModal,
	FantasyCoachModal,
	// GoToApp
} from "../../../components";
import { mergeArrays } from "../../../helpers/league";

import { Td, Tr } from "../../../components/TableX";
import type { TDraftPassedProps } from "../../../components/Draft/withDraftCore";
import Clock from "../../../components/Icons/Clock";
import Ad from "../../../components/Ad";
import Live from "../../../components/Icons/Live";
import withCoachesBox from "../../../components/utils/withCoachesBox";
import { isEitherTrue } from "../../classic/myTeam/helpers";
import { getIsRegenerated, isAllTrue, isAnyTrue } from "../../../helpers";
import {
	StyledBarItem,
	LiveStatusWrapper,
	StyledTabs,
	StyledTab,
	MobileWrapper,
	SelectedContentWrap,
	PlayerTableContainer,
	LiveDraftTable
} from "./liveDraft/LiveDraftStyles";
import {
	kept_by_column,
	dnd_column,
	draft_order_column,
	player_info_column
} from "./liveDraft/tableHeaderColumns";
import MakePickPopup from "./liveDraft/makePickPopup";
import WidgetAutoPick from "./liveDraft/widgetAutoPick";
import TeamViewWidget from "./liveDraft/teamViewWidget";
import { LiveDraftTableBody } from "./liveDraft/LiveDraftTableBody";



const maybeGet = (condition, onTrue, onFalse) => condition ? onTrue : onFalse;

type Props = TDraftPassedProps & {
	showDraftOrder: typeof actions.leagueDraft.showDraftOrder,
	postAutoPickFlag: typeof actions.teamsDraft.postAutoPickFlag,
	postPickedPlayer: typeof actions.teamsDraft.postPickedPlayer,
	fetchLeague: typeof actions.leagueDraft.showDraftLeague,
	teams: TDraftUserTeam[],
	teams_by_id: TDraftTeamsById,
	users_by_id: Object,
	fetchAllStats: Function,
	all_stats: Object,
	draft_order: [],
}

type State = {
	draft_order_widget_open: boolean,
	team_view_widget_open: boolean,
	draft_guide_widget_open: boolean,
	player_for_pick?: TPlayer | typeof undefined,
	picks: Array<Object>,
	drafted_players: Array<number>,
	live_draft_complete: boolean,
	is_my_turn: boolean,
	is_auto_pick_enabled: boolean | typeof undefined,
	next_pick_at: string,
	last_pick?: Object | typeof undefined,
	picks_by_player_id?: { [key:string]: TPlayer },
	selected_team_id?: string | number | typeof undefined,
	ignore_modal: boolean,
	ignore_player_id: number,
	show_fantasy_coach_modal: boolean,
	actions_player_id: number,
	active_tab: string,
	is_mobile:boolean,
	commissioner_picks: number[]
}

class LiveDraftContainer extends React.Component<Props, State> {

	constructor(props, state) {
		super(props, state);

		// this.pubnub.init(this);

		_.bindAll(this, [
			"onToggleDraftOrderWidget",
			"onToggleTeamViewWidget",
			"onClickIgnore",
			"pubNubMessagesHandler",
			"pubNubMessageHandler",
			"isDrafted",
			"confirmPlayerPick",
			"closePickModal",
			"onToggleAutoPick",
			"onChangeTeam",
			"onCloseIgnoreModal",
			"onConfirmIgnoreModal",
			"toggleFantasyCoachModal",
			"keptPlayersAsDrafted",
			"openActionsPlayerId",
			"closeActionsPlayerId",
			"setActiveTab",
			"setPlayerForPick",
			"resize"
		]);
	}

	state = {
		picks: [],
		picks_by_player_id: {},
		drafted_players: [],
		draft_order_widget_open: false,
		team_view_widget_open: true,
		draft_guide_widget_open: false,
		live_draft_complete: false,
		is_my_turn: false,
		player_for_pick: undefined,
		is_auto_pick_enabled: undefined,
		last_pick: undefined,
		next_pick_at: (new Date()).toString(),
		selected_team_id: undefined,
		ignore_modal: false,
		ignore_player_id: 0,
		show_fantasy_coach_modal: false,
		actions_player_id: 0,
		active_tab: "queue",
		is_mobile:isMobile(),
		commissioner_picks: [],
	};

	componentDidMount(){ 
		// On mount set kept players in drafted state, will then remove them from pool

		window.addEventListener('resize', this.resize);
		this.resize();
		this.subscribeToPubNub().getMessages({
			channel: this.props.pub_nub_channel,
			pagesize: 100,
			reverse: true
		}, this.pubNubMessagesHandler);

		const {
			showDraftOrder,
			match: { params: { league_id } },
		} = this.props;

		showDraftOrder({ league_id, details: 1 });
		
	}
	

	componentDidUpdate(prev_props: Props) {
		const { 
			league: { 
				keeper,
				regenerated_from,
				uf_regenerated_from
			}, league, players_by_id
		} = this.props;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		const isKeeperRegen = Boolean(keeper) && isRegenerated;

		// Removed below, if want to show all keepers in drafted we can turn on
		// if(prev_props.league !== this.props.league){
		// 	this.keptPlayersAsDrafted();
		// }
		if(isAllTrue([
			this.state.is_my_turn,
			isKeeperRegen
		])){
			const keeper_picks = _.get(league, "keeper_draft_picks", {});
			const currentPickNumber = _.get(this.state.last_pick, "pick");
			const keeperPlayer = _.get(keeper_picks, currentPickNumber);
			const playerFull = players_by_id[keeperPlayer];
			if(isAllTrue([
				Boolean(keeperPlayer),
				playerFull !== this.state.player_for_pick
			])  ){
				this.setState({
					player_for_pick: playerFull
				});
			}
		}
		const currentLeagueHistory = get(league, 'draft_history', []);
		const prevHistory = get(prev_props.league, 'draft_history', []);
		const lengthDifference = currentLeagueHistory.length !== prevHistory.length;
		if((currentLeagueHistory && lengthDifference) || lengthDifference){
			const draftHistoryTeams = currentLeagueHistory.map(pickObj => Object.keys(pickObj)[0]);
			this.setState({
				...this.state,
				commissioner_picks: draftHistoryTeams
			});
		}
	}
	

	componentWillUnmount() {
		// this.pubnub.unsubscribe({
		// 	channels: [this.props.pub_nub_channel]
		// });
		window.addEventListener('resize', this.resize);
	}
	
	resize() {
		this.setState({
			is_mobile: isMobile()
		});
	}



	// pubnub = new PubNubReact({
	// 	publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
	// 	subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
	// 	ssl: true,
	// });

	openActionsPlayerId(player_id: number){
		this.setState({
			actions_player_id: player_id
		});
	}

	closeActionsPlayerId() {
		this.setState({
			actions_player_id: 0
		});
	}

	setActiveTab({ currentTarget }: Object) {
		const { tab } = currentTarget.dataset;
		this.setState({
			active_tab: tab
		});
	}
	
	keptPlayersAsDrafted() {
		const { league, kept_players_ids } = this.props;
	
		const { keeper, regenerated_from, uf_regenerated_from } = league;
		const { drafted_players } = this.state;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		const isKeeperandRegen = Boolean(keeper) && isRegenerated;


		if(!isKeeperandRegen){
			return;
		}

		const keptPlayers = kept_players_ids;
		this.setState({
			drafted_players: [...drafted_players, ...keptPlayers]
		});

	}
	

	onToggleDraftOrderWidget() {
		const draft_order_widget_open = !this.state.draft_order_widget_open;
		this.setState({ draft_order_widget_open });
	}

	onToggleTeamViewWidget() {
		const team_view_widget_open = !this.state.team_view_widget_open;
		this.setState({ team_view_widget_open });
	}

	subscribeToPubNub(){
		// const { pub_nub_channel } = this.props;

		// this.pubnub.subscribe({
		// 	channels: [pub_nub_channel]
		// });

		// this.pubnub.getMessage(pub_nub_channel, this.pubNubMessageHandler);

		return this;
	}
	toggleFantasyCoachModal() {
		const { show_fantasy_coach_modal } = this.state;
		this.setState({ show_fantasy_coach_modal: !show_fantasy_coach_modal });
	}

	setPlayerForPick(player_for_pick) {
		this.setState({ player_for_pick	});
	}

	get player_list_use(){
		const {filters: {by_drafted}} = this.props;
		const show_drafted_mobile = this.state.is_mobile && by_drafted;
		return show_drafted_mobile ? this.drafted_players : this.players;
	}

	get table_body() {
		const {
			all_stats,
			coachSelectValue,	
			filters,
			isActiveTab,
			is_compressed,
			is_dnd_allowed,
			is_pending_players,
			kept_players_ids,
			league,
			onChangePlayerOrder,
			onDisableDrag,
			onDragEnd,
			onDragEnter,
			onDragLeave,
			onDragOver,
			onDragStart,
			onDrop,
			onEnableDrag,
			pre_draft_list_size,
			show_kept_players,
			table_keys,
			teams_by_id,
			updatePlayerPreDraftListOrder,
			teams,
			has_assistant_coach,
			orderByComparator
		} = this.props;

		const passedProps = {
			openActionsPlayerId: this.openActionsPlayerId,
			closeActionsPlayerId: this.closeActionsPlayerId,
			filters,
			isActiveTab,
			is_compressed,
			is_dnd_allowed,
			is_pending_players,
			kept_players_ids,
			league,
			onChangePlayerOrder,
			onDisableDrag,
			onDragEnd,
			onDragEnter,
			onDragLeave,
			onDragOver,
			onDragStart,
			onDrop,
			onEnableDrag,
			pre_draft_list_size,
			show_kept_players,
			table_keys,
			teams_by_id,
			updatePlayerPreDraftListOrder,
			drafted_players: this.drafted_players,
			getTableMessage: this.getTableMessage,
			isDrafted: this.isDrafted,
			is_commissioner_type: this.is_commissioner_type,
			is_my_turn: this.is_my_turn,
			onClickIgnore: this.onClickIgnore,
			pickAPlayer: this.pickAPlayer,
			picks_by_player_id: this.state.picks_by_player_id,
			player_list_use: this.player_list_use,
			all_stats,
			coachSelectValue,
			teams,
			selected_team_id: this.selected_team_id,
			players: this.players,
			orderByComparator: orderByComparator,
			getAvailablePositions: this.getAvailablePositions,
			toggleFantasyCoachModal: this.toggleFantasyCoachModal,
			has_assistant_coach,
			actions_player_id: this.state.actions_player_id,
			setPlayerForPick: this.setPlayerForPick
		};

		return <LiveDraftTableBody 
			{...passedProps}

		/>;
	}

	get is_auto_pick_enabled(): boolean {
		const { league, teams_by_id } = this.props;
		const { is_auto_pick_enabled } = this.state;

		if(is_auto_pick_enabled === undefined){
			const max_missed_turn = 2;
			const actual_missed_turn = _.get(
				teams_by_id,
				`${league.team_id}.missed_turn`,
				0
			);

			return actual_missed_turn === max_missed_turn;
		}

		return is_auto_pick_enabled;
	}

	pubNubMessagesHandler(result) {
		if(!result) {
			return;
		}

		const picks = _.sortBy(result.map(msg => msg.entry || msg), "pick");
		const pick = _.last(picks);
		const { league, players_by_id } = this.props;
		const { keeper, regenerated_from, uf_regenerated_from } = league;
		const isRegenerated = getIsRegenerated(regenerated_from,uf_regenerated_from);
		const isKeeperRegen = Boolean(keeper) && isRegenerated;
		if(_.eq(_.get(pick, "draft_status"), "complete")) {
			return this.setState({ live_draft_complete: true });
		}

		const drafted_players = picks.map(
			_.property("picked_player_id")
		).filter(_.identity);

		const
			{ user } = this.props,
			be_turn_time = new Date(_.get(pick, "turn_at")),
			sent_at = +(new Date(pick.sent_at)),
			{ draft_turn } = league,
			league_turn_time = draft_turn * 1000,
			turn_time = draft_turn ? Math.min(
				(sent_at + league_turn_time),
				(Date.now() + league_turn_time),
			) : be_turn_time;

		this.setState({
			picks,
			drafted_players,
			picks_by_player_id: _.keyBy(picks.filter(
				_.property("picked_player_id")
			), "picked_player_id"),
			is_my_turn: _.eq(_.get(pick, "turn_user_id"), user.id),
			next_pick_at: (new Date(turn_time)).toString(),
			last_pick: pick,
		});
		if(_.eq(_.get(pick, "turn_user_id"), user.id) && isKeeperRegen){
			const keeper_picks = _.get(league, "keeper_draft_picks", {});
			const currentPickNumber = pick.pick;
			const keeperPlayer = _.get(keeper_picks, currentPickNumber);
			const playerFull = players_by_id[keeperPlayer];
			this.setState({
				player_for_pick: playerFull
			});
		}
	}

	pubNubMessageHandler(result){
		if(!result) {
			return;
		}

		const
			picks = this.state.picks.slice(),
			{ message } = result;

		picks.push(message);

		this.updatePreDraftList(message).pubNubMessagesHandler(picks);

		const {
			league,
			showDraftOrder,
			match: { params: { league_id } }
		} = this.props;

		showDraftOrder({ league_id, details: 1 });

		this.setState({
			is_auto_pick_enabled: Boolean(
				_.get(message, `autopicks.${league.team_id}`, 0)
			),
		});
	}

	updatePreDraftList(message) {
		const {
				league: { team_id },
				pre_draft_list,
				removePlayerFromPreDraftList,
			} = this.props,
			player_id = _.get(message, "picked_player_id");

		if(player_id && pre_draft_list.includes(player_id)) {
			removePlayerFromPreDraftList({
				player_id,
				team_id,
				silent: true,
			});
		}

		return this;
	}

	get players_filter() {
		const { players_filter } = this.props;

		return _.overEvery([
			_.negate(this.isDrafted),
			players_filter
		]);
	}

	get players() {
		const { 
			players, 
			orderByComparator, 
			has_filters,
			show_kept_players,
			kept_players,
			filters
		} = this.props;

		// kept_players_ids is filtering out my keepers
		// but need to check draft_order === 0 to remove
		// other peoples keepers I can not select.

		if(filters.by_ignored){
			return players.filter(player => player.draft_order === 0 && !this.isDrafted(player));
		}
		if(filters.by_drafted){
			return this.drafted_players;
		}
		const playersToUse = show_kept_players 
			? kept_players 
			: 
			players
				.slice()
				.filter(player => (player.draft_order !== 0 && !filters.by_ignored))
				.filter(player => !this.props.kept_players_ids.includes(player.id));

		const { players_show_limit } = this.props;
		if(show_kept_players){
			return playersToUse.sort(orderByComparator).slice(0, players_show_limit);
		}
		if (has_filters) {


			return playersToUse
				.filter(this.players_filter)
				.sort(orderByComparator)
				.slice(0, players_show_limit);
		}
		

		return playersToUse.filter(_.negate(this.isDrafted))
			.sort(orderByComparator)
			.slice(0, players_show_limit);
	}

	get drafted_players() {
		const { players } = this.props;
		const { picks_by_player_id } = this.state;
		// ADD in kept players ordered by team here 

		return _.orderBy(
			players.slice().filter(this.isDrafted),
			player => {
				return _.get(picks_by_player_id, `${player.id}.pick`, 0);
			} ,
			"desc"
		);
	}

	get load_more_btn() {
		if(this.props.load_more_is_hidden || this.props.show_kept_players) {
			return null;
		}

		return <ButtonLoadMore onClick={this.props.onLoadMoreClick}>
			Load more players
		</ButtonLoadMore>;
	}
	columnToCellInfo(column: Object) {
		return {
			table: {
				order_by_desc: this.props.order_by_desc,
				sort_key: this.props.sort_by
			},
			cell: {
				sort_key: column.prop,
				name: column.key,
				short_name: column.key,
				rel: column.prop,
				sortable: true,
				onClick: this.props.onSortColumnChange,
				...column,
			}
		};
	}

	get show_drafted_mobile() {
		const {filters: {by_drafted}} = this.props;
		const {is_mobile} = this.state;
		return is_mobile && by_drafted;
	}
	get table_header() {
		const { order_by_desc, table_keys, sort_by, show_kept_players, league, user } = this.props;
		const {is_my_turn}= this.state;
		const {is_mobile} = this.state;
		const addTextAlignMiddle = show_kept_players ? " align-center" : "";
		const isCommissioner = league.commissioner === _.get(user, "id");
		if(this.player_list_use.length === 0){
			return null;
		}
		const columnToCellInfo = (column: Object) =>{
			return {
				table: {
					order_by_desc: order_by_desc,
					sort_key: sort_by
				},
				cell: {
					sort_key: column.prop,
					name: column.key,
					short_name: column.key,
					rel: column.prop,
					sortable: true,
					onClick: this.props.onSortColumnChange,
					...column,
				}
			};
		};

		const cells = _(table_keys)
			.map(columnToCellInfo)
			.map(cell => renderTableHeaderCell(cell, ThStat, "player-stats"))
			.value();

		
		const { is_coach_active, filters: { by_drafted } } = this.props;
		const show_drafted_mobile = this.show_drafted_mobile;

		return (
			<TableHead
				className={maybeGet(is_coach_active, "coach-stats" , "basic-stats")}
			>
				<Tr className="table-header-row">

					<th className={"absolute-group" + addTextAlignMiddle}>
						<Exist when={show_kept_players}>
							{renderTableHeaderCell(this.columnToCellInfo(kept_by_column))}
						</Exist>
						<Exist when={!show_kept_players}>
							<React.Fragment>
								<Exist when={!is_mobile}>
									{renderTableHeaderCell(this.columnToCellInfo(dnd_column))}
								</Exist>
								
								{renderTableHeaderCell(this.columnToCellInfo(draft_order_column)) }
								<Exist when={!is_mobile}>
									{renderTableHeaderCell(
										this.columnToCellInfo(player_info_column))}
								</Exist>
							</React.Fragment>
						</Exist>
					
						
					</th>
					<Exist when={by_drafted && !is_mobile}>
						<React.Fragment>
							<ThIgnore />
							<ThIgnore />	
						</React.Fragment>
					</Exist>
					{cells}
					<Exist when={!show_drafted_mobile}>
						<ThIgnore width="5% !important" className="last-right-th">
							{maybeGet(
								this.is_commissioner_type,
								<span>
									<Exist when={isAllTrue([
										!this.props.filters.by_ignored,
										!is_my_turn,
										!isCommissioner
									])}>
									Ignore
									</Exist>
									<Exist when={isAnyTrue([
										this.props.filters.by_ignored,
										is_my_turn,
										isCommissioner
									])}>
									Draft
									</Exist>
								</span>,
								<span>
									<Exist when={isBelowTablet()}>
									Action
									</Exist>
									<Exist when={!isBelowTablet()}>
										{(this.is_my_turn 
									&& !this.props.filters.by_ignored) 
											? "Draft" : "Ignore" }
									</Exist>
								</span>
							)}
						</ThIgnore>
					</Exist>
				</Tr>
			</TableHead>
		);
	}

	get columns_size() {
		const size_of_predefined_columns = 4;
		return _.size(this.props.table_keys) + size_of_predefined_columns;
	}

	getTableMessage(message) {
		return (
			<Tr>
				<Td align="center" colSpan={this.columns_size}>
					<Expander>{message}</Expander>
				</Td>
			</Tr>
		);
	}

	isDrafted(player){
		const {league, kept_players_ids} = this.props;
		const isRegenKeeper = _.get(league, "regenerated_from") &&  _.get(league, 'keeper');
		const playerID = _.get(player, "id");
		if(isRegenKeeper){
			return kept_players_ids.includes(playerID) 
				|| this.state.drafted_players.includes(playerID);
		}
		return this.state.drafted_players.includes(playerID);
	}

	getMessages(args: Object, callback) {
		// this.pubnub.history({
		// 	// search starting from this timetoken
		// 	start: args.startToken,
		// 	channel: args.channel,
		// 	// false - search forwards through the timeline
		// 	// true - search backwards through the timeline
		// 	reverse: args.reverse,
		// 	// limit number of messages per request to this value; default/max=100
		// 	count: args.pagesize,
		// 	// include each returned message's publish timetoken
		// 	includeTimetoken: false,
		// 	// prevents JS from truncating 17 digit timetokens
		// 	stringifiedTimeToken: true,
		// }, (status, response) => {
		// 	// holds the accumulation of resulting messages across all iterations
		// 	let results = _.get(args, "results");

		// 	// the retrieved messages from history for this iteration only
		// 	const msgs = _.get(response, "messages", []),
		// 		// timetoken of the first message in response
		// 		firstTT = _.get(response, "startTimseToken"),
		// 		// timetoken of the last message in response
		// 		lastTT = _.get(response, "endTimeToken");

		// 	// if no max results specified, default to 1000
		// 	args.max = _.get(args, "max", 1000);

		// 	if (!_.isEmpty(msgs)) {
		// 		// first iteration, results is undefined, so initialize with first history results
		// 		if (!results) {
		// 			results = msgs;
		// 		}
		// 		// subsequent iterations, results has previous iterartions' results, so concat
		// 		// but concat to end of results if reverse true,
		// 		// otherwise prepend to begining of results
		// 		else {
		// 			args.reverse ? results = results.concat(msgs) : results = msgs.concat(results);
		// 		}
		// 	}

		// 	// we keep asking for more messages if # messages returned by last request is the
		// 	// same at the pagesize AND we still have reached the total number of messages requested
		// 	// same as the opposit of !(msgs.length < pagesize || total == max)
		// 	if (_.eq(msgs.length, args.pagesize) && _.lt(results.length, args.max)) {
		// 		this.getMessages({
		// 			channel: args.channel,
		// 			max: args.max,
		// 			reverse: args.reverse,
		// 			pagesize: args.pagesize,
		// 			startToken: args.reverse ? lastTT : firstTT,
		// 			results: results,
		// 		}, callback);
		// 	}
		// 	// we've reached the end of possible messages to retrieve or hit the 'max' we asked for
		// 	// so invoke the callback to the original caller of getMessages providing
		// 	// the total message results
		// 	else {
		// 		callback(results);
		// 	}
		// });
	};

	get is_my_turn() {
		return this.is_commissioner_type || this.state.is_my_turn;
	}

	pickAPlayer(player_for_pick: TPlayer) {
		if(this.is_commissioner_type || this.state.is_my_turn) {
			this.setState({ player_for_pick	});
		}
	}

	onClickIgnore({ currentTarget: { name, checked } }) {
		const { league: { team_id } } = this.props;
		const player_id = parseInt(_.last(name.split("-")), 10);

		if(checked) {
			this.setState({ ignore_player_id: player_id, ignore_modal: true });
		}
		else {
			this.props.addPlayerToPreDraftList({ player_id,	team_id });
		}
	}

	get selected_team_id() {
		const { league: { team_id } } = this.props;
		const { selected_team_id } = this.state;

		return selected_team_id || team_id;
	}


	getAvailablePositions(player, teams, selected_team_id) {

		const
			{ lineup } = _.get(teams, selected_team_id, {});

		if(!lineup) {
			return [];
		}

		const position_keys = _.concat(_.get(player, "positions", []), "bench");
		const fullLine = _.flow([
			_.partial(_.filter, _, _.negate(_.identity)),
			_.isEmpty
		]);
		

		const returnArr = 
			_.chain(lineup).pick(position_keys).omit('bench')
				.omitBy(fullLine).keys().value().reverse();

		return [...returnArr, "bench"];
	}

	get pick_modal() {
		const
			{ teams } = this.props,
			{ player_for_pick, next_pick_at, last_pick } = this.state;
		const isDraftedBoolean = 
			!this.isDrafted(player_for_pick);

		const isKeeperPick = this.kept_players_array.includes(_.get(player_for_pick, "id"));
		
		const isModal = player_for_pick && this.is_my_turn && isDraftedBoolean && !isKeeperPick;

		return isModal ? (
			<MakePickPopup
				availablePositions={this.getAvailablePositions(player_for_pick, 
					teams, this.selected_team_id)}
				player={player_for_pick}
				pickMsg={last_pick}
				nextPickAt={next_pick_at}
				confirmClick={this.confirmPlayerPick}
				closeClick={this.closePickModal}
				commissioner_type={this.is_commissioner_type}
				team_name={_.get(teams, `${this.selected_team_id}.name`, "")}
			/>
		) : null;
	}

	get kept_players_array() {
		const { league } = this.props;
		const keeper_players = !_.isEmpty(_.get(league, "keeper_draft_picks")) 
			? _.get(league, "keeper_draft_picks") : {};
		return Object.keys(keeper_players).map(pick => {
			return keeper_players[pick];
		});
	}

	closePickModal() {
		const { player_for_pick } = this.state;
		
		
		if(this.kept_players_array.includes(_.get(player_for_pick, "id"))){
			return;
		}
		if(player_for_pick === undefined){
			return;
		}
		this.setState({ player_for_pick: undefined });
	}

	confirmPlayerPick(event: Object) {
		const
			{ postPickedPlayer,league, fetchLeague } = this.props,
			{ id, pos } = event.currentTarget.dataset;

		postPickedPlayer({
			team_id: this.selected_team_id,
			player_id: id,
			position: pos,
		});
		if(this.is_commissioner_type){
			this.setState({
				...this.state,
				commissioner_picks: [...this.state.commissioner_picks, 
					Number(this.selected_team_id)]
			});
			fetchLeague({id: league.id});

		}

		this.closePickModal();
	}

	onToggleAutoPick(event: Object) {
		const is_enabled = event.currentTarget.checked;
		const status = is_enabled ? "on" : "off";
		const { league: { team_id }, postAutoPickFlag } = this.props;

		this.setState({
			is_auto_pick_enabled: event.currentTarget.checked
		});

		postAutoPickFlag({ team_id, status });
	}

	get available_team_positions() {
		const
			{ teams, league } = this.props,
			team = _.get(teams, `${league.team_id}.lineup`, {}),
			available_positions = _.chain(team)
				.toPairs()
				.filter(_.flow([
					([_, val]) => val,
					_.partial(_.filter, _, _.negate(_.identity)),
					_.size,
				]))
				.map(([key]) => key)
				.value();


		return [...available_positions, "bench"] || ["bench"];
	}

	findAvailablePlayerForPick(players, available_positions) {
		const { player_for_pick } = this.state;
		let autoPickPlayer = null;

		if(_.isEmpty(players) || _.isEmpty(available_positions)) {
			return autoPickPlayer;
		}
		if(this.kept_players_array.includes(_.get(player_for_pick, "id", 0))){
			return player_for_pick;
		}

		_.each(players, player => {
			_.each(available_positions, position => {
				const can_be_at_pos = (
					player.positions.includes(parseInt(position, 10))
					|| position === "bench"
				);

				if(can_be_at_pos) {
					autoPickPlayer = player;
				}

				return !autoPickPlayer;
			});

			return !autoPickPlayer;
		});

		return autoPickPlayer;
	}

	get next_player_for_auto_pick() {
		const
			{ pre_draft_list, league, players_by_id, draft_order, user } = this.props,

			{ drafted_players, last_pick } = this.state,
			notDrafted = id => !drafted_players.includes(id),
			available_pdl_players = pre_draft_list.filter(notDrafted),
			is_pdl_not_empty = !_.isEmpty(available_pdl_players),
			available_positions = this.available_team_positions;
		const keeperPicks = _.get(league, "keeper_draft_picks", null);
		const nextPick = _.get(last_pick, "pick");
		const remainingPicks = draft_order.filter(pick => !pick.is_label).map((pick, index) => {
			return {
				...pick,
				pick_num: index + 1
			};
		}).slice(nextPick -1);

		const nextUserPicks = remainingPicks.filter(pick => pick.user_id === user.id);
		const nextUserPickNum = nextUserPicks[0] ? nextUserPicks[0].pick_num : 0;
		const isNextUserKeeper = keeperPicks ? _.get(keeperPicks, nextUserPickNum, false) : false;

		if(Boolean(isNextUserKeeper)){
			return players_by_id[isNextUserKeeper];
		}


		if(is_pdl_not_empty) {
			const
				{ players_by_id } = this.props,
				getPlayerById = id => players_by_id[id],
				available_pdl_player_ids = _.xor(pre_draft_list, drafted_players),
				player = this.findAvailablePlayerForPick(
					available_pdl_player_ids.map(getPlayerById).filter(_.identity),
					available_positions,
				);

			if(player) {
				return player;
			}
		}

		const
			{ players } = this.props,
			not_drafted_player_objects = _.sortBy(
				players.filter(_.flow([
					_.property("id"),
					notDrafted,
				])).filter(_.identity),
				_.property("stats.proj_avg")
			),
			player = this.findAvailablePlayerForPick(
				not_drafted_player_objects,
				available_positions
			);
		return player || null;
	}

	get top_bar() {
		const isCommissioner = this.is_commissioner_type;

		const {
			next_pick_at,
			last_pick,
		} = this.state;

		const
			is_my_turn = this.is_my_turn,
			pick_num = _.get(last_pick, "pick", 1),
			pick_text = (
				`Pick ${pick_num + (is_my_turn ? ", it's your pick!" : " is on the clock")}`
			);

		return (
			<TopBarWrapper is_your_pick={is_my_turn}>
				<StyledBarItem>
					<DraftTimerWrapper is_your_pick={is_my_turn}>
						<Clock size={1.35} />
						<p>{ pick_text }</p>
						<Exist when={!isCommissioner}>
							<CountdownTimer
								className="draft-timer"
								showOnComplete={true}
								hideHoursIfZero={true}
								date={next_pick_at}
								small
							/>
						</Exist>
						
					</DraftTimerWrapper>
					<DraftTeamListOrderIndicator
						currentPick={last_pick}
						is_my_turn={is_my_turn}
						commissioner_picks={this.state.commissioner_picks}
						drafted_picks={this.state.picks}
					/>
				</StyledBarItem>
			</TopBarWrapper>
		);
	}

	get is_commissioner_type() {
		const
			{ user, league } = this.props,
			is_commissioner_draft = league.draft_type === "commissioner",
			you_is_commissioner = league.commissioner === _.get(user, "id");

		// Dirty hack to hide elements until league received
		if(_.isEmpty(league)) {
			return true;
		}

		return is_commissioner_draft && you_is_commissioner;
	}

	get redirect_link() {
		const {
			league: { draft_status, draft_type, commissioner },
			match: { params: { league_id } },
			user: { id: user_id },
		} = this.props;

		const
			{ live_draft_complete	} = this.state,
			draft_not_started = draft_status === "scheduled",
			draft_complete = draft_status === "complete" || live_draft_complete,
			is_commissioner_draft = draft_type === "commissioner",
			you_is_commissioner = commissioner === user_id;

		if (is_commissioner_draft && !you_is_commissioner) {
			// return `/draft/league/${league_id}/about`;
			return false;
		}
		// Comment out to disable redirect/
		if (draft_not_started) {
			return `/draft/league/${league_id}/pre-draft`;
		}

		if (draft_complete) {
			return `/draft/league/${league_id}/team`;
		}

		return false;
	}

	get page_title() {
		const
			{ league: { name } } = this.props,
			title = this.is_commissioner_type ? (
				`Commissioner's draft for ${name}`
			) : (
				<React.Fragment>
					<LiveStatusWrapper>
						Live Draft for {name}
						{" "}
					
						<Live color="#4EA431"/>
					</LiveStatusWrapper>
				</React.Fragment>
			);

		return (
			<DraftLayout>
				<DraftTitleContainer>
					<PageTitle>
						{ title }
					</PageTitle>
				</DraftTitleContainer>
			</DraftLayout>
		);
	}

	onChangeTeam(event){
		if(this.is_commissioner_type) {
			this.setState({
				selected_team_id: event.currentTarget.value
			});
		}
	}

	onCloseIgnoreModal() {
		this.setState({
			ignore_player_id: 0,
			ignore_modal: false
		});
	}

	onConfirmIgnoreModal() {
		const
			{ league: { team_id }, removePlayerFromPreDraftList } = this.props,
			{ ignore_player_id: player_id } = this.state;

		removePlayerFromPreDraftList({ player_id, team_id });
		this.onCloseIgnoreModal();
	}

	get ignore_modal() {
		if(this.state.ignore_modal) {
			return (
				<ConfirmationModal
					header_text="Ignore player"
					body_text="This action will ignore the player in your draft
						 order. It will send the player to the bottom of your draft order list."
					closeClick={this.onCloseIgnoreModal}
					cancelClick={this.onCloseIgnoreModal}
					confirmClick={this.onConfirmIgnoreModal}
				/>
			);
		}

		return null;
	}

	renderTabs() {
		const { active_tab } = this.state;

		return <StyledTabs>
			<StyledTab
				onClick={this.setActiveTab}
				data-tab="queue"
				className={active_tab === "queue" ? "active-tab" : ""}>
				Queue
			</StyledTab>
			<StyledTab
				onClick={this.setActiveTab}
				data-tab="order"
				className={active_tab === "order" ? "active-tab" : ""}>
				Draft Order
			</StyledTab>
			<StyledTab
				onClick={this.setActiveTab}
				data-tab="team"
				className={active_tab === "team" ? "active-tab" : ""}>
				Team
			</StyledTab>
			
			
		</StyledTabs>;
	}

	mobileMainContent() {
		const { active_tab, picks } = this.state;
		const {
			league,
			onFilterByPosition,
			user: { team_name },
		} = this.props;

		const is_commissioner_type = this.is_commissioner_type;

		const isTabActive = tab => {
			return tab === active_tab ? "active" : "hidden";
		};

		return (
			<MobileWrapper>
				<SelectedContentWrap className={isTabActive("queue")}>
					{!is_commissioner_type && (
						<WidgetAutoPick
							widget_open={this.is_auto_pick_enabled}
							onChange={this.onToggleAutoPick}
							player={this.next_player_for_auto_pick}
						/>
					)}
					{this.renderMainContent()}

				</SelectedContentWrap>
				<SelectedContentWrap className={isTabActive("order")}>
					<DraftOrderWidget />
				</SelectedContentWrap>
				<SelectedContentWrap className={isTabActive("team")}>
					<TeamViewWidget
						picks={picks}
						commissioner_type={is_commissioner_type}
						title={team_name}
						onToggle={this.onToggleTeamViewWidget}
						onFilterByPosition={onFilterByPosition}
						widget_open={this.state.team_view_widget_open}
						league={league}
						onChangeTeam={this.onChangeTeam}
						keeper_players={this.props.kept_players}
						drafted_players={this.state.drafted_players}
						
					/>
				</SelectedContentWrap>
				
			</MobileWrapper>
		);

	}

	renderMainContent() {
		const {
			squads,
			positions,
			playerStatuses,
			league: { name },
			league,
			stats_options_list,
			order_by_desc,
			onToggleOrder,
			onFiltersChange,
			onSearchSubmit,
			onSelectChange,
			onCompressedViewClick,
			is_coach_active,
			filters,
			is_compressed,
			show_kept_players,
			toggleKeptPlayers,
			toggleDraftedPlayers,
			filters: { by_drafted, by_ignored },
		} = this.props;
		return <React.Fragment>
			<DraftFilters
				handleChange={onFiltersChange}
				handleSubmit={onSearchSubmit}
				onToggleOrder={onToggleOrder}
				toggleDraftedPlayers={toggleDraftedPlayers}
				order_by_desc={order_by_desc}
				squads={squads}
				positions={positions}
				statuses={playerStatuses}
				is_by_drafted={by_drafted}
				by_ignored={by_ignored}
				is_show_drafted={true}
				selected_by_position={filters.by_position}
				show_ignored={true}
				selectOptions={stats_options_list}
				onSelectChange={this.props.onSelectChange}
				league={league}
				show_kept_players={show_kept_players}
				toggleKeptPlayers={toggleKeptPlayers}
				is_show_keeper_toggle={false}
				show_tablet_column={true}
			/>
			{!this.state.is_mobile && 
			<DraftTableHeader
				title={name}
				onSelectChange={onSelectChange}
				isCoachActive={is_coach_active}
				selectOptions={stats_options_list}
				is_compressed={is_compressed}
				onCompressedViewClick={onCompressedViewClick}
				show_ignored
			/>
			}

			<PlayerTableContainer className="table-con">
				<LiveDraftTable>
					{this.table_header}
					{this.table_body}
				</LiveDraftTable>
			</PlayerTableContainer>
	
			{this.load_more_btn}

		</React.Fragment>;
		
	}

	render() {
		

		if(isEitherTrue(this.props.preloader, isEmpty(this.props.table_keys))) {
			return <Preloader />;
		}
		

		const {
			league,
			onFilterByPosition,
			user: { team_name },
		} = this.props;

		const
			{ last_pick, picks, show_fantasy_coach_modal } = this.state,
			redirect_link = this.redirect_link;

		if(redirect_link) {
			return <Redirect to={redirect_link} />;
		}
		const is_commissioner_type = this.is_commissioner_type;
		const is_not_commissioner_type = !is_commissioner_type;
		const {is_mobile} = this.state;
		// if(is_mobile) {
		// 	return (
		// 		<React.Fragment>
		// 			<GoToApp
		// 				page_name="Live Draft" 
		// 				draft_message
		// 				draft_start={moment(league.draft_start).format('DD/MM/YY HH:mm')}
		// 			/>
		// 			<Footer />
		// 		</React.Fragment>
		// 	);
		// }

		if(is_mobile) {
			return (
				<React.Fragment>
					<Exist when={show_fantasy_coach_modal}>
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
					</Exist>
					
					{ this.ignore_modal }
					{ this.top_bar }
					<AdsContainer>
						<Ad id="matchcentre" />
					</AdsContainer>
					{this.renderTabs()}
					{this.mobileMainContent()}
					{ this.pick_modal }
				</React.Fragment>
			);
		}

		return (
			<div className="playwright-mask-hidden">
				{
					show_fantasy_coach_modal ?
						<FantasyCoachModal
							closeClick={this.toggleFantasyCoachModal}
						/>
						: null
				}
				{ this.ignore_modal }
				{ this.top_bar }
				<AdsContainer>
					<Ad id="matchcentre" />
				</AdsContainer>
				{ this.page_title }
				<DraftLayout>
					<div>
						{this.renderMainContent()}	
					</div>
					<Aside>
						{is_not_commissioner_type && (
							<WidgetAutoPick
								widget_open={this.is_auto_pick_enabled}
								onChange={this.onToggleAutoPick}
								player={this.next_player_for_auto_pick}
							/>
						)}
						{is_not_commissioner_type && (
							<DraftOrderWidget
								picks={picks}
								current_pick={_.get(last_pick, "pick")}
							/>
						)}
						<TeamViewWidget
							picks={picks}
							commissioner_type={is_commissioner_type}
							title={team_name}
							onToggle={this.onToggleTeamViewWidget}
							onFilterByPosition={onFilterByPosition}
							widget_open={this.state.team_view_widget_open}
							league={league}
							onChangeTeam={this.onChangeTeam}
							keeper_players={this.props.kept_players}
							drafted_players={this.state.drafted_players}
						>
						</TeamViewWidget>
					</Aside>
				</DraftLayout>
				{ this.pick_modal }
				<Footer show_key />
			</div>
		);
	}
}

const mapStateToProps = (state: TRootStore, props) => {
	const league = selectors.leagueDraft.getLeague(state, props);
	const numTeams = league.num_teams || 0;
	const teams_by_id = selectors.getTeamsById(state);
	const draft_order = _.get(league, "custom_draft_order", []).length ? 
		mergeArrays(
			league.custom_full_draft_order
				.map((teamID, teamIDIndex) => {
					const roundID = (teamIDIndex/numTeams) + 1;
					const teamData = {
						...teams_by_id[teamID],
						missed_turn: 0,
						pick: teamIDIndex + 1,
						round_id: roundID
					};
					return teamIDIndex % numTeams === 0 ? [
						{ round_id: roundID, is_label: true },
						teamData
					]:teamData;
				})
		):selectors.getDraftOrder(state, props);
	return {
		teams: selectors.complex.team.getTeamsWithFullLineup(state, props),
		teams_by_id: selectors.getTeamsById(state),
		users_by_id: selectors.getUsersFromTeamsById(state),
		all_stats: state.players.all_stats,
		draft_order
	};
};

const mapDispatchToProps = {
	showDraftOrder: actions.leagueDraft.showDraftOrder,
	postAutoPickFlag: actions.teamsDraft.postAutoPickFlag,
	postPickedPlayer: actions.teamsDraft.postPickedPlayer,
	fetchAllStats: actions.fetchAllStats,
	fetchLeague: actions.leagueDraft.showDraftLeague,
};

export const LiveDraft = compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withDraftPlayerModal,
	withDraftCore,
	withCoachesBox
)(LiveDraftContainer);

export default LiveDraft;