// @flow
import React from "react";
import styled from "styled-components";
import * as _ from "lodash";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import colors from "../../assets/css/colors";
import Lock from "../Icons/Lock";

import { priceDiff } from "../PlayerProfile/printStat";
import * as actions from "../../modules/actions";
import * as selectors from "../../modules/selectors";
import type { TPLeague, TPlayer, TRound } from "../../modules/types";


import {getClubLogoIndigenous} from "../../utils/clubLogos";
import { isAllTrue, isAnyTrue } from "../../helpers";
import { isOffSeason } from "../../utils";
import {
	SectionHeader,
	StatHeadItem,
	StatHeadRow,
	StatItem,
	StatItemRow,
} from "./PlayerPopupStyles";

import { DRAFT_FIXTURE_STATS, CLASSIC_FIXTURE_STATS } from "./StatFields";



const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
`;

const FcLink = styled.a`
	color: ${colors.coach};
	cursor: pointer;
`;





type FixtureStatProps = {
    is_classic: boolean,
    league: TPLeague,
    rounds: TRound[],
    player: TPlayer,
    has_coach: boolean,
    actual_round: number,
    fullLink: string,
    player_matches: Object,
    fetchPlayerMatches: Function,
    toggleFantasyCoachModal: Function,
	fetchCustomCoachStats: Function,
    venues: Object,
    coach_stats: Object,
}


class FixtureStatsComponent extends React.PureComponent<FixtureStatProps> {
	constructor(props) {
		super(props);
		
		_.bindAll(this, [
			// 'openSubModal',
			// 'closeSubModal'
		]);
	}

	componentDidMount(){
		const {league} = this.props;
		this.props.fetchPlayerMatches(this.props.player.id);
		if(league.custom_scoring_enabled){
			this.props.fetchCustomCoachStats(league.id);
		}
	}
	get roundsToUse() {
		const filterProperty = isOffSeason() ? "complete" : "scheduled";
		return this.props.rounds.filter(round => (round.status === filterProperty) && round.id > 0);
	}
	
	get isLeagueCustomScoring() {
		return Boolean(this.props.league) && this.props.league.custom_scoring_enabled;
	}


	getRoundMatch(round_id: number) {
		const { rounds, player } = this.props;
		const round = _.find(this.props.rounds, { id: round_id });
		if (player && rounds.length > 0 && round) {
			const player_squad = player.squad_id;
			return _.find(round.matches, m => {
				return m.home_squad_id === player_squad || m.away_squad_id === player_squad;
			});
		}
	}

	getOpponentID(round) {
		const {player} = this.props;
		const match = 
            round.matches.find(match => 
            	[match.home_squad_id, match.away_squad_id]
            		.includes(player.squad_id));
		if(match){
			const isPlayerHome = _.get(match, "home_squad_id", 0) === player.squad_id;
			const opponentID = isPlayerHome ? 
				_.get(match, "away_squad_id", 0) : _.get(match, "home_squad_id", 0);
			return opponentID;
		}
		return 0;
	}

	getOpponentFromRound(round) {
		const {player, actual_round} = this.props;
		const match = 
            round.matches.find(match => 
            	[match.home_squad_id, match.away_squad_id]
            		.includes(player.squad_id));
		if(match){
			const isPlayerHome = _.get(match, "home_squad_id", 0) === player.squad_id;
			const opponentID = isPlayerHome ? 
				_.get(match, "away_squad_id", 0) : _.get(match, "home_squad_id", 0);
			const logo = getClubLogoIndigenous(actual_round, opponentID);
			return <img src={logo} alt="Opponent Logo"/>;
		}
		return "Bye";
	}

	getVenueID(round) {
		const match = this.getRoundMatch(round.id);
		if(match){
			return match.venue_id;
		}
		return 0;
	}

	getVenueName(round) {
		const match = this.getRoundMatch(round.id);
		if(match){
			return match.venue_name;
		}
	}

	getStatID(stat){
		const {is_classic} = this.props;
		return is_classic ? stat.id.split(".")[1] : stat.id;
	}

	customScoringPath() {
		const {league} = this.props;
		return league.custom_scoring_enabled ? "custom_stats" : "stats" ;
	}
	// Have not seperated as dependent on other functions here
	getCoachValue(round, stat) {
		const {player} = this.props;
		const isCustomScoring = this.customScoringPath();
		const playerCoachStats = _.get(player, isCustomScoring, {});
		const statID = this.getStatID(stat);

		if(isAnyTrue([
			statID.includes("proj_score"), 
			statID.includes("be_pct"), 
			statID.includes("break_evens")
		]) ){
			return _.get(playerCoachStats, `${statID}.${round.id}`, "--");
		}
		if(statID.includes("opponents")){
			return _.get(playerCoachStats, `${statID}.${this.getOpponentID(round)}`, "--");
		}
		if(statID.includes("venues")){
			return  _.get(playerCoachStats, `${statID}.${this.getVenueID(round)}`, "--");
		}
		if(statID.includes("proj_price_change")){
			const { proj_prices, prices } = player.stats;
			const all_prices = { ...proj_prices, ...prices };
			if (isAllTrue([all_prices, all_prices[round.id + 1], all_prices[round.id]])) {
				return priceDiff(all_prices[round.id + 1], all_prices[round.id]);
			}
			return "--";
		}
		
		if(statID.includes("avg_at_venue")){
			
			return _.get(playerCoachStats, `venues.${this.getVenueID(round)}`, "--");
		}
		return _.get(playerCoachStats, `${statID}`, "--");
	}

	render() {
		const {is_classic} = this.props;
        
		const statsBasis = this.isLeagueCustomScoring ? "custom_stats" : "stats";
		const stats = is_classic ?  CLASSIC_FIXTURE_STATS : DRAFT_FIXTURE_STATS(statsBasis);
		
        
		return(
			<FlexCol>
				<SectionHeader>
					<p>Fixture Stats</p>
				</SectionHeader>
				<StatHeadRow>
					<StatHeadItem>
                        Rnd
					</StatHeadItem>
					<StatHeadItem>
                        Opp
					</StatHeadItem>
					<StatHeadItem>
                        Venue
					</StatHeadItem>
					{stats.map((stat, index) => {
						return(
							<StatHeadItem key={`fixture-${index}-head`} is_fc={stat.is_coach}>
								{stat.name}
							</StatHeadItem>
						);
					})}
				</StatHeadRow>
				{this.roundsToUse.map((round, index) => {
					return(
						<StatItemRow key={`${index}-round-item-fixture`}>
							<StatItem>
								{round.id}
							</StatItem>
							<StatItem>
								{this.getOpponentFromRound(round)}
							</StatItem>
							<StatItem>
								{this.getVenueName(round)}
							</StatItem>
							{stats.map((stat, index) => {
								const value = this.getCoachValue(round, stat);
								if(stat.is_coach){
									return(
										<StatItem 
											key={`${index}-item-coach-fixture`} 
											is_fc={stat.is_coach}>
											{!this.props.has_coach ? 
												(<FcLink 
													href={"/coach-subscription"} target="_blank">
													<Lock color={colors.coach} />
												</FcLink>):	value}
										</StatItem>
									);
                                    
								}
								return(
									<StatItem key={`${index}-stat-fixture`}>
										{value}
									</StatItem>
								);
							})}
						</StatItemRow>
					);
				})}
			</FlexCol>
		);
		
	}
}

const mapStateToProps = (state, props) => {
	const isClassic = window.location.pathname.includes('classic');
	const league = isClassic ? {} : selectors.leagueDraft.getLeague(state, props);

	return ({
		menu_open: state.uiMenu.open,
		league_id: state.leagues.show.league_id,
		player_matches: state.players.matches_by_id,
		venues: selectors.venues.getVenuesById(state),
		coach_stats: state.players.coach_by_id,
		league,
	});
};

const mapDispatchToProps = {
	fetchPlayerMatches: actions.fetchPlayerMatches,
	fetchCustomCoachStats: actions.fetchAllCoachCustomStats
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FixtureStatsComponent));