// @flow
import * as React from "react";
import styled, { css } from "styled-components";
import _ from "lodash";
import * as numeral from "numeral";
import { Link } from "react-router-dom";
import Table, { TBody, THead, Tr, Td } from "../../components/TableX";
import ChangeIndicatorUp from "../../components/Icons/ChangeIndicatorUp";
import ChangeIndicatorDown from "../../components/Icons/ChangeIndicatorDown";
import ChangeIndicatorHold from "../../components/Icons/ChangeIndicatorHold";
import TeamUserAvatar from "../../components/Team/TeamUserAvatar";
import { below, above } from "../../assets/css/media";
import colors from "../../assets/css/colors";
import { getShortName } from "../../helpers/league";
import type { TCellInfo } from "../../helpers/tables";
import ThStat from "../ThStat";
import { renderTableHeaderCell } from "../../helpers/tables";
import sticky_headers from "../../assets/css/sticky";
import {TRound, TClassicTeam} from "../../modules/types";
import { isOffSeason } from "../../utils";

numeral.nullFormat("- -");

const LadderTable = styled(Table)`
	box-shadow: none;
	margin-top: 20px;
	border-collapse: separate;
`;

const LadderTHead = styled(THead)`
	background-color: transparent;
	${({isOffseason}) => isOffSeason && `
		th{
			top: 50px !important;
		}
	`}
`;

const LadderRow = styled(Tr)`
	${({ is_user_team }) => is_user_team && css`
		background-color: ${colors.secondary.lightGrey};
		> td${TdStat}:nth-child(even), > td${TdStat}:nth-child(odd) {
			background-color: ${colors.secondary.lightGrey};
		}
		:first-child {
			border-bottom: 3px solid ${colors.primary.accent};
		}
		:last-child {
			border-top: 3px solid ${colors.primary.accent};
		}
	`}
`;

const LadderTh = styled(ThStat)`
	color: #1D4073;
	font-size: 12px;
	line-height: 10px;
	text-align: center;
	padding: 0;

	${sticky_headers}

	&.stat {
		width: 140px;
		padding: 0 7px;

		${below.phone`
			width: 45px;
		`}
	}

	&.name {
		text-align: left;
	}
`;

const LadderThSortable = styled(LadderTh)`
	color: #1D4073;
	&.rank:first-child {
		text-align: center;
		width: 60px;

		${below.phone`
			width: 45px;
			padding: 0;
		`}
	}
`;

const TdStat = styled(Td)`
	position: relative;
	color: ${colors.primary.darkGrey};
	font-weight: 400;
	box-sizing: border-box;
	padding: 0;
	height: 60px;

	tr:nth-child(even) > & {
		background: white;
	}

	&.stat {
		width: 140px;
		padding: 0 7px;

		${below.phone`
			width: 45px;
			padding: 0 3px;
		`}
	}
	&.rank {
		padding: 0 5px;
		width: 60px;

		${below.phone`
			width: 45px;
			padding: 0;
		`}
	}
`;

const NameWrapper = styled.div`
	flex: 1 0 0px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 10px;
	box-sizing: border-box;

	${below.phone`
		padding-left: 0px;
	`}
`;

const TeamUserAvatarStyled = styled(TeamUserAvatar)`
	width: 40px;
	height: 40px;

	${below.phone`
		display: none;
	`}
`;

const TeamName = styled.div`
	font-size: 12px;
	line-height: 1.125;
	font-weight: bold;
	text-align: left;
	color: ${colors.primary.primary};
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100px;
	white-space: nowrap;

	${above.phone`
		font-size: 14px;
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 200px;
	`}

	${above.desktop`
		max-width: 250px;
	`}
`;

const TeamWrapper = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	text-decoration: none;

	:hover {
		${TeamName} {
			text-decoration: underline;
		}
	}

	> div {
		flex: 0;
	}
`;

const UserName = styled.div`
	font-size: 12px;
	line-height: 1.28;
	text-align: left;
	color: #7F8A90;
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 100px;
	white-space: nowrap;

	${above.phone`
		max-width: 150px;
	`}

	${above.tablet`
		max-width: 200px;
	`}

	${above.desktop`
		max-width: 250px;
	`}
`;

const ChangeIndicator = styled.div`
	display: flex;
	padding-top: 2px;
	justify-content: center;
`;

type TableColumn = { 
	key: string, 
	prop: string, 
	hide_small?: boolean, 
	format?: string,
	sort_key: string,
	short_key: string,
	/* rel attr of table column. Defaults to value of prop, if not provided */
	rel?: string
};

type Props = {
	compact?: boolean,
	user_id: number,
	ladder_stats: TableColumn[],
	ladder_teams_ordered: Array<Object>,
	rounds_by_id: {
		[id: number|string]: TRound
	},
	selected_round_id: number,
	updateSortBy: Function,
	order: { by: string, direction: 'ASC' | 'DESC' },
	mobile_columns: string[],
	tablet_columns: string[],
	is_ladder: boolean,
	is_h2h_overall: boolean,
	is_classic: boolean,
	league_id: number,
};

type State = {
};

export class ClassicRankings extends React.Component<Props, State> {
	static defaultProps =  {
		mobile_columns: [],
		tablet_columns: [],
		is_ladder: false,
		is_h2h_overall: false,
		is_classic: true,
		league_id: 0
	};
	constructor() {
		super();

		_.bindAll(this, [
			"tableColumnToCellInfo",
			"onSortColumnChange"
		]);
	}
	isActiveTab(sort_prop: string) {
		const { order } = this.props;
		return _.eq(order.by, sort_prop);
	}

	getRank(team: TClassicTeam) {
		return _.get(team, "rank_selected_round", "- -");
	}

	onSortColumnChange(column_value: string) {
		const { updateSortBy } = this.props;

		if (!_.isEmpty(column_value)) {
			updateSortBy(column_value);
		}
	}

	renderRankChangeIndicator(team: TClassicTeam) {
		const { rounds_by_id, selected_round_id } = this.props;
		const round = rounds_by_id[selected_round_id];
		const rank_history = _.get(team, "rank_history", {});

		if (round && !_.isEmpty(rank_history)) {
			if (rank_history[round.id] < rank_history[round.id - 1]) {
				return <ChangeIndicatorUp />;
			}
			else if (rank_history[round.id] > rank_history[round.id - 1]) {
				return <ChangeIndicatorDown />;
			}
		}

		return <ChangeIndicatorHold />;
	}

	tableColumnToCellInfo(cell: TableColumn): TCellInfo {
		return {
			cell: {
				rel: cell.prop,
				...cell,
				name: cell.key,
				short_name: cell.short_key,
				onClick: this.onSortColumnChange,
				sortable: cell.sort_key !== "",
				is_fc: false,
			},
			table: {
				sort_key: this.props.order.by,
				order_by_desc: this.props.order.direction === "DESC"
			}
		};
	}

	renderChange(team: TClassicTeam) {
		const { is_h2h_overall } = this.props;
		if (is_h2h_overall) {
			return null;
		}

		return <TdStat width="20px" rel="rank_change">
			<ChangeIndicator>
				{this.renderRankChangeIndicator(team)}
			</ChangeIndicator>
		</TdStat>;
	}

	renderTableHeader() {
		const { ladder_stats, is_ladder, is_h2h_overall } = this.props;
		const ladder_header_cells = _(ladder_stats)
			.map(this.tableColumnToCellInfo)
			.map(cell_info => renderTableHeaderCell(
				cell_info, 
				LadderThSortable, 
				ci => ci.cell.prop === "rank" ? " rank" : ""
			))
			.value();

		const rank_cell_info = { 
			key: "Pos",
			prop: "rank", 
			hide_small: false, 
			sort_key: is_ladder ? "" : "rank",
			short_key: "Pos"
		};
		const offSeason = isOffSeason();

		return <LadderTHead isOffSeason={offSeason}>
			<Tr>
				{renderTableHeaderCell(
					this.tableColumnToCellInfo(rank_cell_info), 
					LadderThSortable, 
					ci => ci.cell.prop === "rank" ? " rank" : ""
				)}
				{!is_h2h_overall && <LadderTh width="30px" rel="rank_change" />}
				<LadderTh className="name" rel="avatar">
					<span className="column-name">
						<span>Team</span>
						<span>Team</span>
					</span>
				</LadderTh>
				{ ladder_header_cells }
				<LadderTh />
			</Tr>
		</LadderTHead>;
	}

	getTeamPageLink(team: TClassicTeam) {
		const { user_id, is_classic } = this.props;
		if (!is_classic) {
			return this.getDraftTeamPageLink(team);
		}
		if (user_id === team.user_id) {
			return "/classic/team";
		}
		return `/classic/team/user/${team.team_id || team.id}`;
	}

	getDraftTeamPageLink(team: TClassicTeam) {
		const { league_id, user_id } = this.props;

		if (user_id === team.user_id) {
			return `/draft/league/${league_id}/team/`;
		}

		return `/draft/league/${league_id}/team/${team.team_id || team.id}`;
	}

	renderTableRow(team: TClassicTeam) {
		const { ladder_stats, user_id } = this.props;
		const ladder_stats_body_cells = _.map(ladder_stats || [],
			({ key, prop, hide_small, format="0,0", rel=prop }) => {
				const raw_value = _.get(team, prop, null);
				let value = numeral(raw_value).format(format) || "- -";
				if (prop === "value") {
					value = numeral(team[prop] / 1000).format(format) + "k" || "- -";
				}
				if( prop === "points_average_selected_round"){
					value = _.get(team, "points", "--");
					
					if(typeof value === "number"){
						const scoreFlow =  _.get(team, "scoreflow",[]);
						const num_rounds = !_.isEmpty(scoreFlow) ? 
							Object.keys(scoreFlow).length : 1;
						value = Math.floor(value/num_rounds);
					}
				}
				
				return <TdStat
					className='stat'
					key={prop}
					rel={rel}
				>
					{value}
				</TdStat>;
			}
		);
		

		return (
			<LadderRow 
				key={team.team_id || team.id}
				is_user_team={user_id === team.user_id}
			>
				<TdStat
					rel="rank"
					className="rank"
				>
					{this.getRank(team)}
				</TdStat>
				{ this.renderChange(team) }
				<TdStat rel="avatar" className="name">
					<TeamWrapper to={this.getTeamPageLink(team)}>
						<TeamUserAvatarStyled
							user_id={team.user_id}
							avatar_version={team.avatar_version}
							firstname={team.firstname}
							lastname={team.lastname}
						/>
						<NameWrapper>
							<TeamName>{team.team_name || team.name}</TeamName>
							<UserName>{getShortName(team)}</UserName>
						</NameWrapper>
					</TeamWrapper>
				</TdStat>
				{ladder_stats_body_cells}
				<TdStat />
			</LadderRow>
		);
	}

	renderTableBody() {
		const { ladder_teams_ordered} = this.props;
		return (
			<TBody>
				{ladder_teams_ordered.map(team => this.renderTableRow(team))}
			</TBody>
		);
		
	}

	render() {
		const { mobile_columns, tablet_columns } = this.props;
		return (
			<LadderTable
				tablet_columns={tablet_columns}
				mobile_columns={mobile_columns}
			>
				{this.renderTableHeader()}
				{this.renderTableBody()}
			</LadderTable>
		);
	}
}

export default ClassicRankings;